import React, { useEffect, useState } from 'react'
import { Rating } from 'react-simple-star-rating'
import { CardLongItem } from 'components/cards/Card'
import Footer from 'components/Footer'
import styled from '@emotion/styled'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import RingLoading from 'components/lottie/RingLoading'
import { useTranslation } from 'react-i18next'
import { useLazyGetServiceListQuery } from 'v2/src/lib/services/marketplace/nHServiceApi'
import SearchSection from 'components/search'
import { useUserProfileDetailQuery } from 'v2/src/lib/services/profileApi'
import { fGetAUserInf } from 'v2/src/lib/appUtils'
import { constant } from '../products/productConstant'
import CustomPagination from 'v2/src/components/CustomPagination'
import CommonCard from 'v2/src/components/layout/CardCommonLayout'
import Defaul_Institute from 'assets/images/marketplace/institutions.png'
import { Box, Stack } from '@mui/material'
import { FaArrowLeftLong } from 'react-icons/fa6'
import { toast } from 'react-toastify'

const { MIN_RANGE, MAX_RANGE } = constant

export const StyleWrapper = styled.div`
  .optionListContainer {
    position: sticky;
  }
`
// export default function Marketplace() {
export default function MarketplaceServices() {
  const navigate = useNavigate()
  let aUData = fGetAUserInf()
  const aUserProfileId = aUData?.details?.profileId
  const { id: institutionId } = useParams() // Fetching institutionId from URL
  const { state } = useLocation()
  const { t: patient_marketplace } = useTranslation('patient_marketplace')
  const { t: patient_virtual_visit } = useTranslation('patient_virtual_visit')
  const { t: patient_subscription } = useTranslation('patient_subscription')

  /////////////////////////(useState)
  const [pageNumber, setPageNumber] = useState(1)
  const [serviceList, setServiceList] = useState([])
  const [totalPage, setTotalPage] = useState(0)
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const [priceRange, setPriceRange] = useState([MIN_RANGE, MAX_RANGE])
  const [selectedRating, setSelectedRating] = useState()
  const [specialization, setSpecializations] = useState([])
  const [searchTriggered, setSearchTriggered] = useState(false)
  const [visitType, setVisitType] = useState('clinic')
  const [searchText, setSearchText] = useState('')

  const [getServiceList, { data: serviceListData, isLoading: isServiceListDataLoading, error: serviceListDataError }] = useLazyGetServiceListQuery()
  const { data: patientDetails } = useUserProfileDetailQuery({ profileId: aUserProfileId })

  const params = new URLSearchParams()
  // Append basic params
  params.append('per_page', 8)
  params.append('is_active', 1)

  useEffect(() => {
    if (serviceListData) {
      setServiceList(serviceListData?.data)
      setTotalPage(serviceListData?.meta?.last_page)
    }
  }, [serviceListData])

  useEffect(() => {
    if (serviceListDataError) {
      toast.error(serviceListDataError?.data?.message)
    }
  }, [serviceListDataError])

  const getImageHeight = () => {
    if (windowWidth <= 576) {
      return 180
    } else if (windowWidth < 768) {
      return 290
    } else if (windowWidth <= 1000) {
      return 160
    } else if (windowWidth <= 1200) {
      return 240
    } else {
      return 280
    }
  }

  useEffect(() => {
    // Check for visitType
    if (pageNumber) params.append('page', pageNumber)

    if (visitType?.length) params.append('booking_availability', visitType)

    // Check for price range and add price params, excluding the 0, 200 range
    if (!(priceRange[0] === MIN_RANGE && priceRange[1] === MAX_RANGE)) {
      if (priceRange[0] >= MIN_RANGE) params.append('price_min', priceRange[0])
      if (priceRange[1] <= MAX_RANGE) params.append('price_max', priceRange[1])
    }

    // Check for ratings filter
    if (selectedRating || selectedRating !== undefined) {
      params.append('ratings[]', selectedRating === 0 ? '0' : selectedRating)
    }

    // Check for specialization
    if (specialization?.length) {
      specialization.forEach((spec) => params.append('speciality_ids[]', spec))
    }

    // Check for search text
    if (searchText) params.append('search', searchText)

    // Check Any institute has or not
    if (institutionId) params.append('institution_id', institutionId)

    getServiceList(params).unwrap()
  }, [pageNumber, searchTriggered, visitType, institutionId])

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth)
    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  // Handle rating selection
  const handleRatingSelect = (rating) => {
    if (selectedRating === rating) {
      setSelectedRating()
    } else {
      setSelectedRating(rating)
    }
  }

  // Handle Page change
  const handlePageChange = (_, value) => {
    setPageNumber(value) // Update the page on user interaction
  }

  // Handle Price ranges
  const handlePriceRangeChange = (event, newValue) => {
    setPriceRange(newValue)
  }

  return (
    <div className="figma mt-5">
      <div className="page-wrapper">
        <div className="page-content">
          <div className="container-fluid ">
            <div className="row">
              <div className="col-sm-12">
                <div className="page-title-box">
                  <h4 className="page-title" style={{ fontWeight: 600 }}>
                    {patient_marketplace('services')}
                  </h4>
                </div>
              </div>
            </div>

            {/* Bredcrumb sec... */}
            <ol className="breadcrumb justify-content-end pr-0">
              <li className="breadcrumb-item" style={{ fontSize: 15 }}>
                <Link to={'..'}>{patient_marketplace('marketplace')}</Link>
              </li>
              <li className="breadcrumb-item" style={{ fontSize: 15 }}>
                {patient_marketplace('services')}
              </li>
            </ol>

            <div className="row-lg-12">
              <div className="row">
                {/* Search and filter part */}
                <div className="col-12 col-lg-10">
                  <CommonCard cardStyle={{ minHeight: 176 }}>
                    <Stack gap={2} className="col-lg-12 p-0">
                      <h5 className="m-0" style={{ fontSize: 16, fontWeight: 600 }}>
                        {patient_marketplace('search_visit')}
                      </h5>
                      <SearchSection
                        priceRange={priceRange}
                        setPriceRange={setPriceRange}
                        selectedRating={selectedRating}
                        setSelectedRating={setSelectedRating}
                        handleRatingSelect={handleRatingSelect}
                        handlePriceRangeChange={handlePriceRangeChange}
                        specialization={specialization}
                        setSpecializations={setSpecializations}
                        setSearchTriggered={setSearchTriggered}
                        visitType={visitType}
                        setVisitType={setVisitType}
                        searchText={searchText}
                        setSearchText={setSearchText}
                      />
                    </Stack>
                  </CommonCard>
                </div>

                {/* Right top Institutions card */}
                <div className="col-12 col-lg-2">
                  <CommonCard bodyStyle={{ minHeight: 176 }}>
                    <Stack gap={2}>
                      <Box className="d-flex justify-content-between">
                        <img id="marketplace-services-card-media" src={Defaul_Institute} alt="Card image cap" style={{ width: 60, height: 60, borderRadius: 4 }} />
                        <Link onClick={() => navigate('/patient/marketplace/services')}>{patient_marketplace('change_clinic')}</Link>
                      </Box>
                      <Stack gap={1}>
                        <span style={{ fontWeight: 600 }}>{state?.insituteName}</span>
                        <span style={{ backgroundColor: '#f1f5fa', padding: '4px', width: 'fit-content' }} className="rounded-pill">
                          {['in_clinic_visit', 'virtual_visit'].map((item, ind) => {
                            const currentIndex = ind === 0 ? 'clinic' : 'telehealth'
                            return (
                              <button
                                style={{ backgroundColor: visitType === currentIndex ? '#008100' : 'transparent', color: visitType === currentIndex ? '#fff' : 'black', borderRadius: '50px' }}
                                className="border-0 px-2 py-2"
                                onClick={() => setVisitType(currentIndex)}
                              >
                                {patient_marketplace(item)}
                              </button>
                            )
                          })}
                        </span>
                      </Stack>
                    </Stack>
                  </CommonCard>
                </div>
              </div>

              {/* Services part */}
              <div className="col-lg-12">
                <div className="row">
                  <div className="bg-white py-2 rounded" style={{ width: '100%' }}>
                    <h5 className="px-3 d-flex justify-content-between" style={{ fontSize: 15, fontWeight: 600 }}>
                      {/* {patient_marketplace('top_doctors_across_specialties')}{' '} */}
                      {patient_marketplace('doctors_available_for_consultation')}
                      {state?.insituteName && (
                        <div className="d-flex align-items-center" style={{ cursor: 'pointer' }} onClick={() => navigate('/patient/marketplace/services')}>
                          <FaArrowLeftLong className="mr-2" /> back
                        </div>
                      )}
                    </h5>
                    <div class="row g-4 m-0 py-3 px-2" style={{ rowGap: 24 }}>
                      {isServiceListDataLoading ? (
                        <CardLongItem>
                          <div className="d-flex justify-content-center">
                            <RingLoading size={200} />
                          </div>
                        </CardLongItem>
                      ) : serviceList && serviceList.length ? (
                        serviceList.map((item) => (
                          <div className="col-12 col-sm-6 col-md-4" key={item?.id}>
                            <div class="card w-100 h-100 px-sm-2 p-md-3 justify-content-between" style={{ boxShadow: '0 3px 7px -1px rgba(0,0,0,0.4)', gap: 24 }}>
                              <Stack gap={2}>
                                <img
                                  className="card-img-top w-100"
                                  id="wallet-card-media"
                                  src={item?.provider?.profile?.profile_pic_url}
                                  alt="Card image cap"
                                  style={{ height: getImageHeight(), borderRadius: 10 }}
                                />
                                <div className="card-body text-left p-0">
                                  <Stack gap={2} className="justify-content-between">
                                    <div>
                                      <h4 style={{ fontSize: 18, fontWeight: 600 }}>{`${item?.provider?.profile?.user?.first_name} ${item?.provider?.profile?.user?.last_name}`}</h4>
                                      <span>
                                        {`${item?.total_consultations} ${patient_marketplace(item?.total_consultations === 1 ? 'consultation' : 'consultations')}`}
                                        <span className="rounded-circle d-inline-block mx-2" style={{ width: 3, height: 3, backgroundColor: '#D9D9D9', padding: 4 }} />
                                      </span>
                                      <span style={{ color: '#008100', fontWeight: 600 }}>${item?.amount}</span>
                                    </div>
                                    <Stack gap={'10px'}>
                                      <div>
                                        <span style={{ width: 110, display: 'inline-block' }}>{patient_marketplace('specialization')}:</span>
                                        <span style={{ fontWeight: 600 }}>{item?.provider?.specialities?.map((spec) => spec.name)}</span>
                                      </div>
                                      <div>
                                        <span style={{ width: 110, display: 'inline-block' }}>{patient_marketplace('experience')}:</span>
                                        <span style={{ fontWeight: 600 }}>{item?.total_experience}</span>
                                      </div>
                                      <div className="d-flex align-items-center flex-wrap">
                                        <span style={{ width: 110, display: 'inline-block' }}>{patient_marketplace('ratings')}:</span>
                                        <span className="d-flex align-items-center" style={{ fontWeight: 600 }}>
                                          <span className="mr-1" style={{ marginTop: 2 }}>
                                            {Math.round(item?.provider?.average_rating)}
                                          </span>
                                          <Rating
                                            fillColor="#ffb822"
                                            emptyColor="white"
                                            SVGstrokeColor="#f1a545"
                                            SVGstorkeWidth={1}
                                            size={17}
                                            allowFraction={true}
                                            initialValue={item?.provider?.average_rating}
                                            readonly={true}
                                            style={{ marginBottom: 3 }}
                                          />
                                        </span>
                                      </div>
                                    </Stack>
                                  </Stack>
                                </div>
                              </Stack>

                              {/* Buttons */}
                              <div class="card-footer bg-transparent p-0 text-center">
                                {visitType === 'telehealth' ? (
                                  !patientDetails?.data?.has_active_subscription ? (
                                    <button
                                      className="btn btn-success rounded-pill"
                                      style={{ width: 'auto', maxWidth: 200, padding: '6px 24px' }}
                                      onClick={() => {
                                        Swal.fire({
                                          html: patient_virtual_visit('complete_subscription_msg'),
                                          confirmButtonText: patient_subscription('subscribe_now'),
                                        }).then(() => {
                                          navigate('/patient/subscription', { replace: true })
                                        })
                                      }}
                                    >
                                      {patient_marketplace('consult_now')}
                                    </button>
                                  ) : (
                                    <Link to={'/virtualvisit'} state={{ visitType, servicesId: item?.id, specialityId: item?.provider?.specialities[0]?.id }}>
                                      <button className="btn btn-success rounded-pill" style={{ width: 'auto', maxWidth: 200, padding: '6px 24px' }}>
                                        {patient_marketplace('consult_now')}
                                      </button>
                                    </Link>
                                  )
                                ) : (
                                  <Link to={'/patient/marketplace/booking/' + item?.id}>
                                    <button className="btn btn-success rounded-pill" style={{ width: 'auto', maxWidth: 200, padding: '6px 24px' }}>
                                      {patient_marketplace('consult_now')}
                                    </button>
                                  </Link>
                                )}
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <div className="text-center w-100 py-5" style={{ fontWeight: 600, fontSize: 15 }}>
                          {serviceListData?.message}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {/* Pagination for All */}
              {serviceList && serviceList.length && <CustomPagination count={totalPage} currentPage={pageNumber} handlePageChange={handlePageChange} />}
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  )
}
