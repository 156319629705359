import React, { useEffect, useState } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import TestImage from '../../../../../assets/images/img-1.jpg'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useAddCartItemsMutation, useRemoveCartItemMutation, useUpdateCartItemQuantityMutation } from 'v2/src/lib/services/marketplace/nHCartApi'
import { toast } from 'react-toastify'
import AddToCartButton from 'v2/src/components/common/AddToCartButton'
import { BasePriceIfHasDiscount, DiscountedButton } from './common/ProductPriceComponents'
import { ProductCardRatings, ProductSelect } from './helper/ProductsCardHelper'

export default function AllMarketPlaceProductsList({ productList, productListMessage, setIscartBoxDisplay, cartData }) {
  const { t: patient_marketplace } = useTranslation('patient_marketplace')
  const { items: cartItems } = cartData
  // fetch cart data from api
  const [addCartItems] = useAddCartItemsMutation()
  const [updateCartItemQuantity] = useUpdateCartItemQuantityMutation()
  const [removeCartItem] = useRemoveCartItemMutation()

  const [activeProducts, setActiveProducts] = useState([])
  const [selectedVariant, setSelectedVariant] = useState({})

  // set default state value and get prouductlist and cart items
  useEffect(() => {
    // Check if both productList and cartItems are available
    if (productList && cartItems) {
      const newSelectedVariants = {}

      cartItems?.forEach((item) => {
        const productId = item?.product?.id
        const variantId = item?.product?.variant?.id

        if (!newSelectedVariants[productId]) {
          // Check if variants array exists and has at least one element
          newSelectedVariants[productId] = variantId || (item?.product?.variants?.length && item?.product?.variants[0]?.id)
        }
      })

      productList?.forEach((product) => {
        if (!newSelectedVariants[product.id]) {
          // Check if variants array exists and has at least one element
          newSelectedVariants[product.id] = product?.variants?.length && product?.variants[0]?.id
        }
      })

      setSelectedVariant(newSelectedVariants)
      setActiveProducts(cartItems.map((item) => item?.product?.id)) // Update activeProducts state
      setIscartBoxDisplay(cartItems?.length > 0) // Show cart box if there are items
    }
  }, [productList, cartItems, setIscartBoxDisplay]) // Include all necessary dependencies

  // Handle adding product/item in cart
  const handleAddToCart = async (product) => {
    const variantId = selectedVariant[product.id] || product.variants[0]?.id
    const params = { product_id: product.id, quantity: 1 }

    // Check and send it if product has variants or not.
    if (product?.variants?.length) {
      params.variant_id = variantId
    }

    const { product_id, quantity, variant_id } = params
    try {
      const payload = { product_id, quantity, variant_id }
      const response = await addCartItems(payload).unwrap()

      // Update the activeProducts and selectedVariant state
      setActiveProducts((prev) => [...prev, product.id])
      setSelectedVariant((prev) => ({ ...prev, [product.id]: variantId }))

      // Show success message (optional)
      toast.success(response?.message || `${product.name} added to cart!`)
    } catch (error) {
      toast.error(error?.data?.message || 'Error adding to cart.')
    }
  }

  // Handle increase and decrease quantity
  const handleQuantityChange = async (productId, action) => {
    const cartProduct = cartItems?.find((item) => item.product.id === productId && item?.variant?.id === selectedVariant[productId])
    if (!cartProduct) return

    // Get the current cart item ID
    const cartItemId = cartProduct.id

    const updatedQuantity = action === 'increase' ? cartProduct.quantity + 1 : cartProduct.quantity - 1

    if (updatedQuantity <= 0) {
      const response = await removeCartItem(cartItemId)
      toast.success(response?.message || 'Product removed from cart!')
      return
    } // Avoid decreasing below 1

    const payload = { id: cartItemId, quantity: updatedQuantity }

    try {
      const response = await updateCartItemQuantity(payload).unwrap()
      toast.success(response?.message || 'Cart Item updated!')
    } catch (error) {
      toast.error(error?.data?.message || 'Failed to update quantity')
    }
  }

  // Handle variants changes of product
  const handleVariantChange = (product, variantId) => {
    setSelectedVariant((prev) => ({ ...prev, [product.id]: variantId }))
  }

  return (
    <Row style={{ rowGap: 16 }}>
      {productList?.length > 0 ? (
        productList?.map((product) => {
          const cartProduct = cartItems?.find((item) => item.product.id === product.id && item?.variant?.id === selectedVariant[product.id])
          const cartQuantity = cartProduct ? cartProduct.quantity : 0

          const img_url = product?.images?.find((img) => img?.is_featured === 1)?.image_url
          const variantOptions = product?.variants || []
          const selectedVariantId = selectedVariant[product.id] || variantOptions[0]?.id

          // Price calculation based on the selected variant
          const selectedVariantData = variantOptions.find((variant) => variant.id === selectedVariantId)
          const price = selectedVariantData?.discounted_price || selectedVariantData?.base_price

          return (
            <Col key={product.id} xs={12} sm={6} md={4} lg={3}>
              <Card style={{ padding: 14, margin: 0 }} className="h-100">
                {/* Card Image */}
                <Link to={`/patient/marketplace/products/${product?.id}`}>
                  <div className="item-img-bg-block">
                    <Card.Img variant="top" src={img_url || TestImage} alt={`${product?.name}-image`} style={{ cursor: 'pointer', borderRadius: 10, height: 160 }} />
                  </div>
                </Link>

                {/* Card Body */}
                <Card.Body className="px-0">
                  <Link to={`/patient/marketplace/products/${product?.id}`}>
                    <Card.Title style={{ fontSize: 20, fontWeight: 600 }}>{product?.name}</Card.Title>
                  </Link>

                  {/* Brand and quantity */}
                  <div>
                    <Link style={{ color: '#303e67', textDecoration: 'underline' }} to={'/patient/marketplace/products'}>
                      {product?.brand?.name || '-'}
                    </Link>
                    <span className="d-inline-block rounded-circle mx-2" style={{ width: 3, height: 3, backgroundColor: '#D9D9D9', padding: 4 }} />
                    <span style={{ fontWeight: 500 }}>
                      {product?.quantity} {product.dosage_form?.name}
                    </span>
                    {/* {product?.variants?.length > 0 && (
                      <>
                        <span className="d-inline-block rounded-circle mx-2" style={{ width: 3, height: 3, backgroundColor: '#D9D9D9', padding: 4 }} />
                        <span style={{ fontWeight: 500 }}>{product?.variants[0]?.title}</span>
                      </>
                    )} */}
                  </div>

                  {/* Ratings */}
                  <ProductCardRatings avegRatings={product?.average_rating || 0} totalRatings={product?.total_ratings} classes="justify-content-between" style={{ gap: 0 }} alignItems="normal" />

                  {/* Dropdown for variants */}
                  <ProductSelect
                    value={variantOptions?.length > 0 ? selectedVariant[product.id] || variantOptions[0]?.id : product.id}
                    variantOptions={variantOptions}
                    onchange={handleVariantChange}
                    product={product}
                  />

                  {/* Price & Discount */}
                  <div>
                    {product?.variants?.length ? ( // If product has variants
                      <>
                        <div className="d-flex align-items-center">
                          <span className="mr-2" style={{ fontSize: 24, fontWeight: 600 }}>
                            ${price}
                          </span>
                          {selectedVariantData?.base_price && selectedVariantData.discounted_price && <BasePriceIfHasDiscount basePrice={selectedVariantData.base_price} />}
                        </div>
                        {selectedVariantData?.discounted_price && <DiscountedButton basePrice={selectedVariantData?.base_price} discountedPrice={selectedVariantData?.discounted_price} />}
                      </>
                    ) : (
                      <>
                        {product?.discounted_price ? (
                          <div className="d-flex align-items-center">
                            <span className="mr-2" style={{ fontSize: 24, fontWeight: 600 }}>
                              ${product.discounted_price}
                            </span>
                            <BasePriceIfHasDiscount basePrice={product.base_price} />
                          </div>
                        ) : (
                          <span className="mr-2" style={{ fontSize: 24, fontWeight: 600 }}>
                            ${product.base_price}
                          </span>
                        )}
                        {product?.discounted_price && <DiscountedButton basePrice={product?.base_price} discountedPrice={product?.discounted_price} />}
                      </>
                    )}
                  </div>
                </Card.Body>

                {/* Card Footer */}
                <Card.Footer style={{ backgroundColor: 'transparent' }} className="text-center mt-3">
                  {/* Add to cart Reusable Button */}
                  <AddToCartButton activeProducts={activeProducts} product={product} cartQuantity={cartQuantity} onAddToCart={handleAddToCart} onQuantityChange={handleQuantityChange} />
                </Card.Footer>
              </Card>
            </Col>
          )
        })
      ) : (
        <div className="text-center w-100">{productListMessage}</div>
      )}
    </Row>
  )
}
