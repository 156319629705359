import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import ReactDOM, { createRoot } from 'react-dom'
import FullCalendar from '@fullcalendar/react'
import timeGridPlugin from '@fullcalendar/timegrid'
import Footer from 'components/Footer'
import moment from 'moment'
import { format } from 'date-fns'
import Swal from 'sweetalert2'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import { Rating } from 'react-simple-star-rating'
import { store } from 'redux/store'
import { Provider } from 'react-redux'
import useAuth from 'hooks/useAuth'
import { useTranslation } from 'react-i18next'
import { convertSlotsToEvents } from 'v2/src/lib/appUtils'
import { fGetAUserInf } from 'v2/src/lib/appUtils'
import { TableTitle } from 'components/table/Tables'
import CardItem from 'components/cards/Card'
import RingLoading from 'components/lottie/RingLoading'
import CheckoutForm2 from './CheckoutForm2'
import { useLazyGetServiceListQuery, useLazyGetSingleServiceQuery } from 'v2/src/lib/services/marketplace/nHServiceApi'
import { useFetchTimeSlotsMutation } from 'v2/src/lib/services/provider/providerApi'
import { useUserProfileDetailQuery } from 'v2/src/lib/services/profileApi'
import { usePostAppointmentMutation } from 'v2/src/lib/services/nHAppointmentApi'
import styled from '@emotion/styled'
import { toast } from 'react-toastify'
import { Skeleton, Stack } from '@mui/material'

import { USERTYPE, STRIPE_PUBLISHABLE_KEY } from 'constants'
import ServicesOffered from './marketplacehelper/ServicesOffered'
import CommonCard from 'v2/src/components/layout/CardCommonLayout'

// add styles as css
export const StyleWrapper = styled.div`
  .fc-button
  .fc-prev-button,
  .fc-button.fc-next-button,
  .fc-timegrid-event,
  .fc-button.fc-button-primary {
    background: green;
    background-image: none;
    padding: 6px 14px;
  }
  .fc-toolbar-title { font-size: 22px; font-weight: 600 }
  .fc-button.fc-prev-button { background: #f8f8fc; color: #008100 }
  .fc-button { border:0 }

  .fc .fc-view-harness { height:500px !important }
  .fc .fc-scrollgrid-sync-inner { padding: 8px }

  .fc-event { cursor: pointer;waves-effect }
  .styles-module_image__2hdkJ {
    height : auto;
    max-height: 780px;
    margin-bottom : 120px;
  }
  .styles-module_navigation__1pqAE { z-index : 1080 }
  .styles-module_wrapper__1I_qj { background-color : rgba(0 0 0 / 50%) }
  img { object-fit:contain; opacity: 1.0 !important; }
`
export default function Booking() {
  const { auth } = useAuth()
  const navigate = useNavigate()
  const { id: serviceId } = useParams()
  const { t: patient_marketplace } = useTranslation('patient_marketplace')
  const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY)
  const startDateRef = useRef(new Date())
  let aUData = fGetAUserInf()
  const usrProfileId = aUData?.details?.profileId
  const arrComps = ['user', 'role', 'language', 'addresses', 'patient']

  ///////////***(useState)
  const [practiceId, setPracticeId] = useState()
  const [singleServiceDetails, setSingleServiceDetails] = useState({})
  const [serviceClinicsDetails, setServiceClinicsDetails] = useState()
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [selectedPracticeId, setSelectedPracticeId] = useState(null)
  const [allDaySlots, setallDaySlots] = useState([])
  const [selectedPracticeDetail, setSelectedPracticeDetail] = useState()
  const [slots, setSlots] = useState([])
  const [selectedServiceOfferedId, setSelectedServiceOfferedId] = useState()

  const [getSingleService, { data: singleServiceData, isLoading: isSingleServiceDataLoading, error: singleServiceDataError }] = useLazyGetSingleServiceQuery()
  const [fetchTimeSlots] = useFetchTimeSlotsMutation()
  const { data: profileApiData } = useUserProfileDetailQuery({ profileId: usrProfileId, arrComps }, { skip: usrProfileId === null, refetchOnMountOrArgChange: true })
  const [postAppointment] = usePostAppointmentMutation()
  const [getServiceList, { data: serviceOfferedData, isLoading: isServiceOfferedLoading, error: serviceOfferedDataError }] = useLazyGetServiceListQuery()

  useEffect(() => {
    if (serviceId) {
      setSelectedServiceOfferedId(Number(serviceId))
      getSingleService({ id: serviceId })
    }
  }, [serviceId])

  useEffect(() => {
    if (serviceOfferedDataError) toast.error(serviceOfferedDataError?.data?.message)
    if (singleServiceDataError) toast.error(singleServiceDataError?.data?.message)
  }, [serviceOfferedDataError, singleServiceDataError])

  useEffect(() => {
    const params = new URLSearchParams()
    if (singleServiceData && singleServiceData?.data) {
      setPracticeId(singleServiceData?.data?.provider?.practices[0]?.id)
      const serviceClinicsDetails = singleServiceData?.data?.provider?.practices
      setSingleServiceDetails({ ...singleServiceData?.data })
      serviceClinicsDetails?.length && setServiceClinicsDetails([...serviceClinicsDetails])
      if (singleServiceData?.data?.provider) {
        params.append('provider_id', singleServiceData?.data?.provider?.id)
        getServiceList(params).unwrap()
      }
    }
  }, [singleServiceData])

  const handleDateSelect = (selectInfo) => {
    let calendarApi = selectInfo.view.calendar
    calendarApi.unselect() // clear date selection
  }

  const postDatefun = async ({ params, patient_marketplace }) => {
    const { start_date, end_date, practice_id, provider_id, institutionName } = params
    try {
      const res = await fetchTimeSlots({ start_date, end_date, practice_id, provider_id }).unwrap()

      // Create a transformed data structure from the initial fetch response
      let transformedData = []

      // Check each practice and gather available slots
      Object.keys(res.data).forEach((date) => {
        if (res.data[date].length > 0) {
          // Check if there is already an entry for this practice_id
          let practiceEntry = transformedData.find((entry) => entry.id === practice_id)
          if (!practiceEntry) {
            practiceEntry = { id: practice_id, dates: [], institutionName }
            transformedData.push(practiceEntry)
          }
          practiceEntry.dates.push(date)
        }
      })

      // Ensure all requested practices are included even if no slots are available
      if (!transformedData.some((entry) => entry.id === practice_id)) {
        transformedData.push({ id: practice_id, dates: [], institutionName })
      }

      // Helper function to format dates
      const formatDays = (dates) => {
        if (dates.length === 0) {
          return 'N/A'
        }
        const momentDates = dates.map((date) => moment(date, 'YYYY-MM-DD')).sort((a, b) => a.diff(b))
        const weekdays = momentDates.map((date) => date.format('dddd'))
        if (weekdays.length > 1) {
          let consecutive = true
          for (let i = 1; i < momentDates.length; i++) {
            if (momentDates[i].diff(momentDates[i - 1], 'days') !== 1) {
              consecutive = false
              break
            }
          }
          if (consecutive) {
            return `${weekdays[0]} to ${weekdays[weekdays.length - 1]}`
          } else {
            return weekdays.join(', ').replace(/, ([^,]*)$/, ' & $1')
          }
        }
        return weekdays.join('')
      }

      transformedData.forEach((entry) => {
        entry.formattedDays = formatDays(entry.dates)
      })

      setallDaySlots((prevSlots) => {
        const slotMap = new Map(prevSlots.map((slot) => [slot.id, slot]))

        transformedData.forEach((slot) => {
          slotMap.set(slot.id, { ...slotMap.get(slot.id), ...slot })
        })

        return Array.from(slotMap.values())
      })
      const transformedSlots = convertSlotsToEvents(res?.data || {}, patient_marketplace)
      if (practiceId) {
        setSlots(transformedSlots)
      }
    } catch (error) {
      console.error(error?.data?.message)
    }
  }

  useEffect(() => {
    if (practiceId) {
      const params = {
        start_date: format(startDate, 'yyyy-MM-dd'),
        end_date: format(endDate, 'yyyy-MM-dd'),
        practice_id: practiceId,
        provider_id: singleServiceData?.data?.provider?.id,
        institutionName: selectedPracticeDetail?.institution?.name,
      }
      postDatefun({ params, patient_marketplace })
    } else if (serviceClinicsDetails && startDate) {
      serviceClinicsDetails.forEach((practice) => {
        const params = {
          start_date: format(startDate, 'yyyy-MM-dd'),
          end_date: format(endDate, 'yyyy-MM-dd'),
          practice_id: practice?.id,
          provider_id: practice?.provider_id,
          institutionName: practice?.institution?.name,
        }
        postDatefun({ params, patient_marketplace })
      })
    }
  }, [serviceClinicsDetails, startDate, selectedPracticeId])

  const handleAvailability = (id) => {
    setSelectedPracticeId(id)
    setSelectedPracticeDetail(serviceClinicsDetails.find((item) => item.id === id))
  }

  async function handlePostAppointment(start_time) {
    const param = {
      start_time: start_time,
      provider_id: singleServiceData?.data?.provider?.id,
      practice_id: practiceId,
      service_id: selectedServiceOfferedId,
    }

    try {
      const response = await postAppointment({ ...param }).unwrap()
      if (response && response.message) {
        toast.success(response.message)
        setSlots([])
        setSelectedPracticeId(null)
        navigate('/patient/appointments')
      }
    } catch (err) {
      const errorMessage = err?.data?.message || 'An error occurred while processing the appointment.'
      toast.error(errorMessage)
    }
  }

  const handleClose = () => {
    Swal.close()
    setSlots([])
    setSelectedPracticeId(null)
  }

  const handleEventClick = (clickInfo) => {
    const formattedStart = moment(clickInfo.event.start).format('YYYY-MM-DD')
    const formattedTime = moment(clickInfo.event.start).format('hh:mm A')
    const isAvailable = clickInfo.event.extendedProps.isAvailable
    if (!isAvailable) {
      return false // Stop the event from being interacted with
    }

    if (auth.userType === USERTYPE.patient) {
      Swal.fire({
        title: patient_marketplace('book_your_appointment'),
        html: `
          ${patient_marketplace('booking_confirmation')}<br> 
          <div style="background-color: #f1f5fa; padding: 15px; margin-top: 32px; color: #303e67; border-radius: 5px;">
            <span style="font-size: 14px;"><b style="font-size: 18px;">${moment(clickInfo.event.startStr).format('MMMM DD, YYYY')}</b> at <b style="font-size: 18px;">${formattedTime}</b></span>
            <br><br>
            <div style="display: flex; gap: 15px;">
              <!-- Image Container -->
              <div style="background-color: rgb(182, 195, 232); border-radius: 10px; padding: 5px;">
                <img 
                  style="height: 114px; width: 180px; object-fit: contain; border-radius: 5px;" 
                   src=${singleServiceDetails?.provider?.profile?.profile_pic_url}
                />
              </div>
      
              <!-- Text Container -->
              <div style="color: #303e67;">
                <div style="text-align: start;"> 
                  <span style="width: 65px; display: inline-block; font-size: 14px;">${patient_marketplace('doctor')}:</span> 
                  <span style="font-size: 14px;"><b>${singleServiceDetails?.provider?.profile?.user?.first_name} ${singleServiceDetails?.provider?.profile?.user?.last_name}</b></span>
                </div>
                <div style="text-align: start;"> 
                   <span style="width: 65px; display: inline-block; font-size: 14px;">${patient_marketplace('clinic')}:</span> 
                   <span style="font-size: 14px;"><b>${singleServiceDetails?.provider?.practices?.[0]?.institution.name || '-'}</b></span>
                </div>
                <div style="text-align: start;">
                  <span style="width: 65px; display: inline-block; font-size: 14px;">${patient_marketplace('address')}:</span> 
              
               <span style="font-size: 14px;"><b>${
                 singleServiceDetails?.provider?.practices?.[0]?.institution?.addresses?.find((address) => address.is_primary === 1)?.address_line_1 || '-'
               }</b></span>

                </div>
              </div>
            </div>
          </div>
          <div id="payment-container" style="margin-top:10px;"></div>  <!-- Placeholder for React -->
          `,
        width: '650px',
        confirmButtonColor: '#008000',
        cancelButtonColor: '#d33',
        showConfirmButton: profileApiData?.data?.has_approved_insurance_document,
        showCancelButton: profileApiData?.data?.has_approved_insurance_document,
        confirmButtonText: patient_marketplace('book_now'),
        cancelButtonText: patient_marketplace('cancel_appointment'),
        reverseButtons: true, // Swap Confirm & Cancel Button Order
        customClass: { confirmButton: 'swal-confirm-btn-booking', cancelButton: 'swal-cancel-btn-booking' },
        didOpen: () => {
          const container = document.getElementById('payment-container')
          if (container) {
            const param = { provider_id: selectedPracticeDetail?.provider_id, practice_id: selectedPracticeDetail?.id, service_id: serviceId, start_time: formattedStart + ' ' + formattedTime }
            const root = createRoot(container) // React 18 approach

            root.render(
              <Provider store={store}>
                {!profileApiData?.data?.has_approved_insurance_document && (
                  <Elements stripe={stripePromise}>
                    <CheckoutForm2 param={param} handlePostAppointment={handlePostAppointment} handleClose={handleClose} />
                  </Elements>
                )}
              </Provider>
            )
          }
        },
        willClose: () => {
          const container = document.getElementById('payment-container')
          if (container) {
            ReactDOM.unmountComponentAtNode(container)
          }
        },
      }).then(({ isConfirmed }) => {
        if (isConfirmed && profileApiData?.data?.has_approved_insurance_document) {
          const start_time = formattedStart + ' ' + formattedTime
          handlePostAppointment(start_time)
        }
      })
    }
  }

  return (
    <div className="page-wrapper">
      <div className="page-content">
        <div className="container-fluid">
          {/* Page Title */}
          <TableTitle title={<h4 style={{ fontWeight: 600 }}>{patient_marketplace('services')}</h4>}></TableTitle>

          {/* Bread Crumb of Provider */}
          <div>
            <ol className="breadcrumb justify-content-end" style={{ fontSize: 15 }}>
              {isSingleServiceDataLoading ? (
                <>
                  <Skeleton variant="text" width={80} sx={{ margin: '0 5px' }} /> /
                  <Skeleton variant="text" width={80} sx={{ margin: '0 5px' }} /> /
                  <Skeleton variant="text" width={80} sx={{ margin: '0 5px' }} />
                </>
              ) : (
                <>
                  <li className="breadcrumb-item">
                    <Link to=".." style={{ color: 'inherit' }}>
                      {patient_marketplace('marketplace')}
                    </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="../services">{patient_marketplace('services')}</Link>
                  </li>
                  <li className="breadcrumb-item active">{singleServiceDetails?.provider?.profile?.user?.first_name + ' ' + singleServiceDetails?.provider?.profile?.user?.last_name}</li>
                </>
              )}
            </ol>
          </div>

          {/* Providers details */}
          {isSingleServiceDataLoading ? (
            <CardItem>
              <div className="d-flex justify-content-center w-100">
                <RingLoading size={200} />
              </div>
            </CardItem>
          ) : (
            <>
              <div className="row">
                {/* Make changes on below as per needs */}
                {/* <div className="col-lg-8 px-2"> */}
                <div className="col-lg-12 px-2">
                  <div className="card mb-3">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-3">
                          <div className="text-center rounded" style={{ backgroundColor: '#efece8' }}>
                            {/* <img src={singleServiceDetails?.provider?.profile?.profile_pic_url} alt="Fixed Size" className="img-fluid" style={{ width: '100%', height: '245px', objectFit: 'cover' }} /> */}
                            <img src={singleServiceDetails?.provider?.profile?.profile_pic_url} alt="Fixed Size" className="img-fluid" style={{ width: '100%', height: '190px', objectFit: 'cover' }} />
                          </div>
                        </div>
                        <div className="col-9 d-flex flex-column justify-content-between">
                          <div>
                            <div className="d-flex justify-content-between align-items-center">
                              <span className="" style={{ fontSize: '20px', fontWeight: 'bold' }}>
                                {singleServiceDetails?.provider?.profile?.user?.first_name + ' ' + singleServiceDetails?.provider?.profile?.user?.last_name}
                              </span>
                              <div className="d-flex align-items-center flex-wrap">
                                <h5 className="m-0">
                                  {patient_marketplace('ratings')}:
                                  <span style={{ fontSize: '15px', fontWeight: 'bold', margin: '0px 5px' }}>{Math.round(singleServiceDetails?.provider?.average_rating)}</span>
                                </h5>
                                <span>
                                  <Rating
                                    fillColor="#ffb822"
                                    emptyColor="white"
                                    SVGstrokeColor="#f1a545"
                                    SVGstorkeWidth={1}
                                    size={17}
                                    allowFraction={true}
                                    initialValue={singleServiceDetails?.provider?.average_rating}
                                    readonly={true}
                                    style={{ marginBottom: 2 }}
                                  />
                                </span>
                              </div>
                            </div>
                            <div>
                              <span>{`${singleServiceDetails?.total_consultations || '-'} ${patient_marketplace(
                                singleServiceDetails?.total_consultations === 1 ? 'consultation' : 'consultations'
                              )}`}</span>
                              <span className="rounded-circle d-inline-block mx-2" style={{ width: 3, height: 3, backgroundColor: '#D9D9D9', padding: 4 }} />
                              <span style={{ fontWeight: 600, color: '#008100' }}>${singleServiceDetails?.amount || '-'}</span>
                            </div>
                          </div>

                          {/* About */}
                          <Stack gap={1}>
                            <div>
                              <span style={{ width: 110, display: 'inline-block' }}>{patient_marketplace('specializations')}:</span>
                              <span style={{ fontSize: 15, fontWeight: 600, marginLeft: '8px' }}>{singleServiceDetails?.provider?.specialities?.map((spec) => spec.name) || '-'}</span>
                            </div>
                            <div>
                              <span style={{ width: 110, display: 'inline-block' }}>{patient_marketplace('experience')}:</span>
                              <span style={{ fontSize: 15, fontWeight: 600, marginLeft: '8px' }}>{singleServiceDetails?.total_experience || '-'}</span>
                            </div>
                            <div>
                              <span style={{ width: 110, display: 'inline-block' }}>{patient_marketplace('service_title')}:</span>
                              <span style={{ fontSize: 15, fontWeight: 600, marginLeft: '8px' }}>{singleServiceDetails?.name}</span>
                            </div>
                          </Stack>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Comment out as per new figma UI */}
                {/* {auth.userType === USERTYPE.patient ? (
                  <div className="col-lg-4 px-2">
                    <div className="card">
                      <div className="card-body p-3">
                        <h4 style={{ fontWeight: 'bold', margin: 0 }}>{patient_marketplace('clinic_visits')}</h4>

                        <div className="row mt-3 d-flex flex-column m-0" style={{ gap: '10px' }}>
                          {allDaySlots.map((item) => (
                            <div className="col-12 p-2 rounded" style={{ backgroundColor: '#f1f5fa', color: 'black' }}>
                              <span style={{ fontSize: '12px', fontWeight: 'bold', color: '#3c4971' }}>{item?.formattedDays}</span>
                              <p style={{ margin: '0' }}>{item?.institutionName}</p>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null} */}
              </div>

              <div className="row">
                {/* Comment out as per new figma UI */}
                <div className="col-lg-4 px-2">
                  {/* <div className="card">
                    <div className="card-body p-3">
                      <h4 style={{ fontWeight: 'bold', margin: 0 }}>{patient_marketplace('start_booking')}</h4>
                      <div className="row mt-3 d-flex flex-column m-0" style={{ gap: '10px' }}>
                        {allDaySlots.map((item) => (
                          <div className="col-12 p-2 rounded pl-3 py-3 border" style={{ backgroundColor: '#f1f5fa', color: 'black' }}>
                            <span style={{ fontSize: '12px', fontWeight: 'bold', color: '#3c4971' }}>{item?.formattedDays}</span>
                            <p style={{ marginBottom: '18px', letterSpacing: '0.5px' }}>{item?.institutionName}</p>
                            <button className=" btn rounded-pill px-4 text-white" style={{ backgroundColor: 'green' }} onClick={() => handleAvailability(item?.id)}>
                              {patient_marketplace('check_availability')}
                            </button>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div> */}
                  <CommonCard>
                    <ServicesOffered
                      selectedServiceOfferedId={selectedServiceOfferedId}
                      setSelectedServiceOfferedId={setSelectedServiceOfferedId}
                      serviceOfferedData={serviceOfferedData}
                      isServiceOfferedLoading={isServiceOfferedLoading}
                    />
                  </CommonCard>
                </div>

                {/* Make changes on below as per needs */}
                <div className="col-lg-8 px-2">
                  <div className="card marketplace-cal">
                    <div className="card-body">
                      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                      <div id="calendar"></div>
                      <StyleWrapper>
                        <FullCalendar
                          plugins={[timeGridPlugin]}
                          headerToolbar={{ left: 'title', center: '', right: 'prev,next' }}
                          initialView="timeGridWeek"
                          // slotDuration={'00:30:00'}
                          // slotLabelInterval={'00:30:00'}
                          slotDuration={'01:00:00'}
                          slotLabelInterval={'01:00:00'}
                          validRange={{ start: new Date().toISOString().split('T')[0] }}
                          events={slots}
                          selectable={true}
                          selectMirror={true}
                          dayMaxEvents={true}
                          select={handleDateSelect}
                          eventClick={handleEventClick}
                          datesSet={(arg) => {
                            const { view } = arg
                            startDateRef.current = arg.start
                            const startDate = new Date(view.currentStart)
                            startDate.setDate(startDate.getDate() + 1)

                            const formattedStartDate = startDate.toISOString().split('T')[0]
                            if (view.type === 'timeGridWeek') {
                              const endDate = new Date(startDate)
                              endDate.setDate(startDate.getDate() + 6)

                              const formattedEndDate = endDate.toISOString().split('T')[0]
                              setStartDate(formattedStartDate)
                              setEndDate(formattedEndDate)
                            }
                          }}
                          eventContent={(arg) => {
                            const eventTitle = arg.event.title
                            return { html: `<div class="">${eventTitle}</div>` } // Add the dynamic class
                          }}
                        />
                      </StyleWrapper>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        <Footer />
      </div>
    </div>
  )
}
