import { useUserProfileDetailQuery } from 'v2/src/lib/services/profileApi'
import CardComponent from './card'
import { useTranslation } from 'react-i18next'
import { fGetAUserInf } from 'v2/src/lib/appUtils'
import { toast } from 'react-toastify'
import CopyJSX from './CopySVG'

const NuiCoins = () => {
  const { t: wallet } = useTranslation('wallet')
  let aUData = fGetAUserInf()
  const aUserProfileId = aUData?.details?.profileId
  const arrComps = ['user', 'role', 'language', 'addresses', 'patient']

  const { data: profileApiData } = useUserProfileDetailQuery({ profileId: aUserProfileId, arrComps }, { skip: aUserProfileId === null, refetchOnMountOrArgChange: true })

  const walletData = profileApiData?.data?.user?.wallet

  const nuiCoinsData = [
    {
      img: '/assets/images/Image_01.jpg',
      mutedText: wallet('start_by_connecting'),
      boldText: wallet('social_media'),
      buttonText: wallet('connect'),
    },
    {
      img: '/assets/images/Image_02.jpg',
      mutedText: wallet('start_by_sharing'),
      boldText: wallet('post_on_NIU'),
      buttonText: wallet('share'),
    },
    {
      img: '/assets/images/Image_03.jpg',
      mutedText: wallet('start_by_sharing'),
      boldText: wallet('niu_post_on_social_media'),
      buttonText: wallet('share'),
    },
  ]

  const shortenAddress = (address) => {
    return address?.length && address.slice(0, 6) + '......' + address.slice(-6)
  }

  const handleCopy = () => {
    navigator.clipboard
      .writeText(walletData?.wallet_address)
      .then(() => {
        toast.success('Wallet address copied to clipboard!')
      })
      .catch((err) => {
        toast.error('Failed to copy wallet address: ' + err)
      })
  }

  return (
    <div>
      <div className="p-3">
        <div className="d-flex justify-content-between mb-2">
          <span style={{ fontSize: '25px', fontWeight: 'bold' }}>
            {walletData?.coin_balance ? parseFloat(walletData?.coin_balance).toFixed(4) : '0.0000'} {wallet('coins')}
          </span>
          <h6>
            <span className="text-muted mr-2">{wallet('wallet')}</span>

            <span style={{ marginRight: '3px' }}>{shortenAddress(walletData?.wallet_address)}</span>
            <span className="rounded p-1" style={{ cursor: 'pointer' }} onClick={handleCopy}>
              <CopyJSX />
            </span>
          </h6>
        </div>
        <div className="d-flex justify-content-between">
          <span style={{ fontWeight: '600' }}>{wallet('total_balance')}</span>
          <button className="rounded-pill border border-success py-2 text-success" style={{ width: '90px' }}>
            {wallet('kyc_done')}
          </button>
        </div>
      </div>
      <div className="bg-white pt-3 ">
        <h5>{wallet('how_to_earn_coins?')} </h5>
        <div className="row">
          {nuiCoinsData.map((item, index) => (
            <div className="col-md-4 py-2" key={index}>
              <CardComponent arg={item} />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default NuiCoins
