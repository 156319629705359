import { ListItem } from '@mui/material'
import { Box, Stack } from '@mui/system'
import { useTranslation } from 'react-i18next'
import { FaCheckCircle, FaRegCheckCircle } from 'react-icons/fa'
import RingLoading from 'components/lottie/RingLoading'

export default function ServicesOffered({ serviceOfferedData, isServiceOfferedLoading, setSelectedServiceOfferedId, selectedServiceOfferedId }) {
  const { t: patient_marketplace } = useTranslation('patient_marketplace')
  return (
    <Stack gap={2}>
      <h4 className="m-0" style={{ fontSize: 15, fontWeight: 600 }}>
        {patient_marketplace('services_offered')}
      </h4>
      <Stack gap={2}>
        {isServiceOfferedLoading ? (
          <div className="d-flex justify-content-center w-100">
            <RingLoading size={100} />
          </div>
        ) : serviceOfferedData && serviceOfferedData?.data && serviceOfferedData?.data?.length > 0 ? (
          serviceOfferedData?.data?.map((item, ind) => (
            <ListItem
              key={ind}
              sx={{
                border: '1px solid #F1F5FA',
                borderRadius: 1,
                padding: '16px 12px',
                justifyContent: 'space-between',
                alignItems: 'center',
                gap: 3,
                cursor: 'pointer',
              }}
              onClick={() => setSelectedServiceOfferedId(item?.id)}
            >
              <Box display="flex" alignItems="center" gap={1}>
                {selectedServiceOfferedId == item?.id ? <FaCheckCircle size={20} color="#008100" /> : <FaRegCheckCircle size={20} color="#D9D9D9" />}
                <span style={{ fontWeight: 600 }}>{item.name}</span>
              </Box>
              <span style={{ fontWeight: 600, color: '#008100' }}>${item.amount}</span>
            </ListItem>
          ))
        ) : (
          <div className="text-center w-100 py-5" style={{ fontWeight: 600, fontSize: 15 }}>
            {serviceOfferedData?.data?.message || patient_marketplace('no_service_found')}
          </div>
        )}
      </Stack>
    </Stack>
  )
}
