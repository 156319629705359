import * as yup from 'yup'

export const ResetPasswordSchema = (error_message) => {
  return yup
    .object({
      Password: yup
        .string()
        .required(error_message('required_error)'))
        .min(8, error_message('password_min_length_error'))
        .max(50, error_message('password_max_length_error)'))
        .matches(/^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*()_+-={}|;:'\",.<>?]).{8,}$/, error_message('password_format_error')),
      ConfirmPassword: yup
        .string()
        .required(error_message('required_error)'))
        .oneOf([yup.ref('Password')], error_message('confirm_password_error')),
    })
    .required()
}
export default ResetPasswordSchema
