import { Box, MenuItem, Rating, Select } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { IoIosArrowDown } from 'react-icons/io'
import { IoStar } from 'react-icons/io5'

const selectMenuProps = {
  PaperProps: {
    style: { borderRadius: 12, marginTop: 8, backgroundColor: '#fff' },
  },
}

export function ProductSelect({ value, onchange, product, variantOptions }) {
  return (
    <div className="py-2">
      {variantOptions?.length > 0 ? (
        <Select
          value={value}
          onChange={(e) => onchange(product, e.target.value)}
          sx={{
            width: '100%',
            maxHeight: 28,
            backgroundColor: '#F1F5FA',
            borderRadius: 16,
            '& .MuiOutlinedInput-notchedOutline': { borderColor: 'transparent' },
            '&:hover .MuiOutlinedInput-notchedOutline': { border: 0 },
            '& .MuiInputBase-input': { py: 1, px: 2, fontSize: 13.5, color: '#42323B' },
            '& .MuiSelect-icon': { marginRight: 1, color: '#4B4B4B' },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': { border: 'none' },
          }}
          MenuProps={selectMenuProps}
          IconComponent={IoIosArrowDown}
          disabled={variantOptions?.length === 1 ? true : false}
        >
          {variantOptions?.map((variant) => (
            <MenuItem key={variant.id} value={variant.id} sx={{ fontFamily: 'inherit', '&:hover': { backgroundColor: '#FAFBFF' } }}>
              {variant.title} {product?.dosage_form?.name}
            </MenuItem>
          ))}
        </Select>
      ) : (
        <Select
          value={value}
          onChange={(e) => onchange(product, e.target.value)}
          sx={{
            width: '100%',
            maxHeight: 28,
            backgroundColor: '#F1F5FA',
            borderRadius: 16,
            '& .MuiOutlinedInput-notchedOutline': { borderColor: 'transparent !important' },
            '& .MuiInputBase-input': { py: 1, px: 2, fontSize: 13.5, color: '#42323B' },
            '& .MuiSelect-icon': { marginRight: 1, color: '#4B4B4B' },
          }}
          IconComponent={IoIosArrowDown}
          disabled
        >
          <MenuItem value={product.id}>{product?.dosage_form?.name || product?.name}</MenuItem>
        </Select>
      )}
    </div>
  )
}

export function ProductCardRatings({ totalRatings, avegRatings, classes, style, alignItems }) {
  const { t: patient_marketplace } = useTranslation('patient_marketplace')
  return (
    <div className={`d-flex align-items-center my-2 ${classes}`} style={{ flexWrap: 'wrap', gap: 32, ...style }}>
      <Box sx={{ display: 'flex', alignItems: alignItems ? alignItems : 'center' }}>
        <span style={{ marginRight: 5, fontWeight: 600, fontSize: 14 }}>{avegRatings}</span>
        <Rating
          name="text-feedback"
          value={avegRatings}
          readOnly
          precision={0.5}
          sx={{ fontSize: 18, '& .MuiRating-iconEmpty': { color: '#D9D9D9' }, '& .MuiRating-iconFilled': { color: '#FFCA22' } }}
          emptyIcon={<IoStar style={{ opacity: 0.55 }} />}
        />
      </Box>
      <span style={{ color: '#9EA5BA' }}>
        ({totalRatings} {patient_marketplace('ratings')})
      </span>
    </div>
  )
}
