import React, { useEffect, useState } from 'react'
import { Row, Col, Form, InputGroup, Card } from 'react-bootstrap'
import { FaChevronDown, FaChevronUp, FaSearch } from 'react-icons/fa'
import { IoClose, IoStar } from 'react-icons/io5'
import Slider from '@mui/material/Slider'
import { useTranslation } from 'react-i18next'
import { FaCheck } from 'react-icons/fa6'
import { Box, Button, IconButton, Input, List, ListItem, ListItemText, Stack, Typography } from '@mui/material'
import { useLazyGetBrandsQuery, useLazyGetProdctFormQuery } from 'v2/src/lib/services/marketplace/nHProductsApi'
import RingLoading from 'components/lottie/RingLoading'
import { constant } from './productConstant'
import useDebounce from 'hooks/useDebounce'
import '../marketplace.css'

const { ratingOptions, MIN_RANGE, MAX_RANGE } = constant

const ProductSearchSection = ({
  priceRange,
  selectedRating,
  brandIds,
  productForm,
  setBrandIds,
  setProductForm,
  setPriceRange,
  setSelectedRating,
  setSearchTriggered,
  searchText,
  setSearchText,
  handleRatingSelect,
  handlePriceRangeChange,
  handleBrandSelection,
  handleProductFormSelection,
}) => {
  const { t: patient_marketplace } = useTranslation('patient_marketplace')

  const [isBrandNameOpen, setIsBrandNameOpen] = useState(false)
  const [isProductFormOpen, setIsProductFormOpen] = useState(false)
  const [isPriceRangeOpen, setIsPriceRangeOpen] = useState(false)
  const [isRatingsOpen, setIsRatingsOpen] = useState(false)
  const [searchQuery, setSearchQuery] = useState()

  const [getBrands, { data: brandData, isLoading: isBrandLoading }] = useLazyGetBrandsQuery()
  const [getProdctForm, { data: productsFormData, isLoading: isProductFormLoading }] = useLazyGetProdctFormQuery()
  const debouncedSearchText = useDebounce(searchQuery, 300) // Debounce searchText by 300 ms

  // handle search in field of brand name
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value)
  }

  useEffect(() => {
    getProdctForm().unwrap()
  }, [])

  // useEffect for Search Brand Name
  useEffect(() => {
    const params = new URLSearchParams()
    if (debouncedSearchText) {
      params.append('search', debouncedSearchText)
    }
    getBrands(params).unwrap()
  }, [debouncedSearchText])

  return (
    <div>
      <Row className="align-items-center m-0 flex-nowrap" style={{ border: '1px solid #1b181830', borderRadius: '50px', padding: '2px', gap: 8, maxWidth: 800 }}>
        {/* Specializations Displayed in Input */}
        <Col md className="p-0">
          <InputGroup>
            <Form.Control
              type="text"
              placeholder={patient_marketplace('type_your_search')}
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value)
              }}
              className="border-0"
              style={{ borderRadius: '50px', padding: '10px 15px' }}
            />
          </InputGroup>
        </Col>

        {/* Search Button */}
        <span md="auto">
          <Button
            className="border-0 text-white"
            sx={{ width: { xs: 'auto', md: 125 }, textTransform: 'capitalize' }}
            style={{ backgroundColor: '#008100', borderRadius: '50px', padding: '8px 20px' }}
            onClick={() => setSearchTriggered((prev) => !prev)}
          >
            {patient_marketplace('search')}
          </Button>
        </span>
      </Row>

      {/* Filters */}
      <Stack gap={1} className="mt-3">
        <Stack direction={{ xs: 'column', md: 'row' }} gap={1.5} className=" align-items-center">
          <Typography auto sx={{ width: { xs: '100%', md: 'auto' } }}>
            <span style={{ fontWeight: 500, fontSize: 15 }}>{patient_marketplace('filter_by')}:</span>
          </Typography>

          {/* Brand Name filters */}
          <Box sx={{ width: { xs: '100%', md: 'auto' }, position: 'relative' }}>
            <button className="w-100 filterDropdownStyle" onClick={() => setIsBrandNameOpen(!isBrandNameOpen)}>
              <span style={{ color: '#303e67' }}>{patient_marketplace('brand_name')}</span>
              <FaChevronDown style={{ transform: isBrandNameOpen ? 'rotate(180deg)' : 'rotate(0deg)', transition: 'transform 0.3s ease-in-out' }} />
            </button>

            {isBrandNameOpen && (
              <Card className="filterDropdownMenuStyle" style={{ width: 220 }}>
                <Card.Body className="p-0 overflow-y-auto" style={{ maxHeight: 280, scrollbarColor: '#f1f5fa transparent', scrollbarWidth: 'thin' }}>
                  <List className="p-0 m-0" sx={{ listStyle: 'none' }}>
                    <div style={{ padding: 8 }}>
                      <Input
                        placeholder="Search Brand"
                        value={searchQuery}
                        onChange={handleSearchChange}
                        endAdornment={<FaSearch style={{ color: '#9ea5ba' }} />}
                        className="w-100 p-3"
                        sx={{ fontSize: 14, borderRadius: 50, backgroundColor: '#f1f5fa', height: 40, '&.MuiInput-root::before': { border: 0 }, '&.MuiInput-root:hover::before': { border: 0 } }}
                      />
                    </div>
                    {isBrandLoading ? (
                      <div className="d-flex justify-content-center w-100">
                        <RingLoading size={30} />
                      </div>
                    ) : brandData?.data?.length > 0 ? (
                      brandData?.data?.map((option) => (
                        <ListItem
                          key={option.id}
                          sx={{ paddingY: 1.5, cursor: 'pointer', transition: 'background-color 0.3s ease', '&:hover': { backgroundColor: '#FAFBFF' } }}
                          onClick={() => handleBrandSelection(option.id)}
                        >
                          <ListItemText primary={option.name} sx={{ margin: 0, '& .MuiTypography-root': { fontSize: 14, fontWeight: 500 } }} />

                          {brandIds.includes(option.id) && (
                            <IconButton sx={{ padding: 0 }}>
                              <FaCheck size={14} color="#008100" />
                            </IconButton>
                          )}
                        </ListItem>
                      ))
                    ) : (
                      <Typography variant="body2" color="textSecondary" sx={{ padding: 2 }}>
                        {brandData?.message}
                      </Typography>
                    )}
                  </List>
                </Card.Body>
              </Card>
            )}
          </Box>

          {/* ------------------------------------ */}

          {/* Product Form filters */}
          <Box sx={{ width: { xs: '100%', md: 'auto' }, position: 'relative' }}>
            <button className="w-100 w-md-auto filterDropdownStyle" onClick={() => setIsProductFormOpen(!isProductFormOpen)}>
              <span style={{ color: '#303e67' }}>{patient_marketplace('product_form')}</span>
              <FaChevronDown style={{ transform: isProductFormOpen ? 'rotate(180deg)' : 'rotate(0deg)', transition: 'transform 0.3s ease-in-out' }} />
            </button>

            {isProductFormOpen && (
              <Card className="filterDropdownMenuStyle" style={{ width: 220 }}>
                <Card.Body className="p-0 overflow-y-auto" style={{ maxHeight: 280, scrollbarColor: '#f1f5fa transparent', scrollbarWidth: 'thin' }}>
                  <ul className="m-0 p-0" style={{ listStyle: 'none' }}>
                    {isProductFormLoading ? (
                      <div className="d-flex justify-content-center w-100">
                        <RingLoading size={30} />
                      </div>
                    ) : productsFormData?.data?.length > 0 ? (
                      productsFormData?.data?.map((option) => (
                        <li
                          className="d-flex justify-content-between align-items-center"
                          key={option.id}
                          style={{ padding: '12px', cursor: 'pointer', transition: 'background-color 0.3s ease' }}
                          onClick={() => handleProductFormSelection(option.id)}
                        >
                          <span style={{ marginRight: '10px', fontWeight: 500, fontSize: 15 }}>{option.name}</span>

                          {productForm.includes(option.id) && (
                            <span className="text-success">
                              <FaCheck color="#008100" />
                            </span>
                          )}
                        </li>
                      ))
                    ) : (
                      <Typography variant="body2" color="textSecondary" sx={{ padding: 2 }}>
                        {patient_marketplace('no_results')}
                      </Typography>
                    )}
                  </ul>
                </Card.Body>
              </Card>
            )}
          </Box>

          {/* ------------------------------------ */}

          {/* Price ranges filters */}
          <Box sx={{ width: { xs: '100%', md: 'auto' }, position: 'relative' }}>
            <button className="w-100 w-md-auto filterDropdownStyle" onClick={() => setIsPriceRangeOpen(!isPriceRangeOpen)}>
              <span style={{ color: '#303e67' }}> {patient_marketplace('price_range')}</span>
              <span style={{ transform: isPriceRangeOpen ? 'rotate(180deg)' : 'rotate(0deg)', transition: 'transform 0.3s ease-in-out' }}>
                {isPriceRangeOpen ? <FaChevronUp /> : <FaChevronDown />}
              </span>
            </button>

            {isPriceRangeOpen && (
              <Card className="filterDropdownMenuStyle" style={{ width: 320, padding: 8 }}>
                <Card.Body style={{ paddingBottom: 5 }}>
                  <Slider
                    value={priceRange}
                    onChange={handlePriceRangeChange}
                    min={MIN_RANGE}
                    max={MAX_RANGE}
                    step={1}
                    marks={[
                      { value: priceRange[0], label: `$${priceRange[0]}` }, // Selected Min
                      { value: priceRange[1], label: `$${priceRange[1]}` }, // Selected Max
                    ]}
                    className="m-0 mt-3 w-100"
                    sx={{
                      color: 'green',
                      '& .MuiSlider-track': { height: 10 },
                      '& .MuiSlider-markLabel': { fontWeight: 500, top: -20 },
                      '& .MuiSlider-thumb': { width: 24, height: 24, backgroundColor: 'green', outline: '2px solid #fff !important' },
                      '& .MuiSlider-rail': { height: 10, backgroundColor: '#ddd' },
                    }}
                  />
                </Card.Body>
              </Card>
            )}
          </Box>

          {/* ------------------------------------ */}

          {/* Ratings filter */}
          <Box sx={{ width: { xs: '100%', md: 'auto' }, position: 'relative' }}>
            <button className="w-100 w-md-auto filterDropdownStyle" onClick={() => setIsRatingsOpen(!isRatingsOpen)}>
              <span style={{ color: '#303e67' }}>{patient_marketplace('ratings')}</span>
              <FaChevronDown style={{ transform: isRatingsOpen ? 'rotate(180deg)' : 'rotate(0deg)', transition: 'transform 0.3s ease-in-out' }} />
            </button>

            {isRatingsOpen && (
              <Card className="rounded-0 filterDropdownMenuStyle">
                <ul className="p-0 m-0" style={{ listStyle: 'none' }}>
                  {ratingOptions.map((option) => (
                    <li
                      className="d-flex justify-content-between align-items-center"
                      key={option.value}
                      style={{ padding: '12px', cursor: 'pointer', backgroundColor: '#FAFBFF', transition: 'background-color 0.3s ease' }}
                      onClick={() => handleRatingSelect(option.value)}
                    >
                      <div>
                        <span style={{ marginRight: '10px', fontWeight: 500, fontSize: 15 }}>{option.label}</span>
                        {[...Array(5)].map((_, i) => (
                          <IoStar key={i} color={i < option.value ? '#FFCA22' : '#D9D9D9'} style={{ marginRight: '4px' }} size={20} />
                        ))}
                      </div>
                      {selectedRating == option.value && (
                        <span className="text-success">
                          <FaCheck color="#008100" />
                        </span>
                      )}
                    </li>
                  ))}
                </ul>
              </Card>
            )}
          </Box>
        </Stack>

        {/* Filters list */}
        <div className="d-flex flex-wrap align-items-center justify-content-between" style={{ gap: 12, marginLeft: '4.5rem' }}>
          <Stack direction={{ md: 'row' }} gap={2}>
            {brandIds?.length > 0 && (
              <span className="filterSelectionBG">
                {`${patient_marketplace('brand_name')}: ${brandData?.data
                  .filter((item) => brandIds.includes(item.id))
                  .map((item) => item.name)
                  .join(', ')}`}
                <IoClose
                  size={16}
                  onClick={() => {
                    setBrandIds([])
                    setSearchTriggered((prev) => !prev)
                  }}
                />
              </span>
            )}

            {productForm?.length > 0 && (
              <span className="filterSelectionBG">
                {`${patient_marketplace('product_form')}: ${productsFormData?.data
                  .filter((item) => productForm.includes(item.id))
                  .map((item) => item.name)
                  .join(', ')}`}
                <IoClose
                  size={16}
                  onClick={() => {
                    setProductForm([])
                    setSearchTriggered((prev) => !prev)
                  }}
                />
              </span>
            )}

            {priceRange[0] !== MIN_RANGE || priceRange[1] !== MAX_RANGE ? (
              <span className="filterSelectionBG">
                {`${patient_marketplace('price_range')}: $${priceRange[0]}-$${priceRange[1]}`}
                <IoClose
                  size={16}
                  onClick={() => {
                    setPriceRange([MIN_RANGE, MAX_RANGE])
                    setSearchTriggered((prev) => !prev)
                  }}
                />
              </span>
            ) : null}

            {(selectedRating || selectedRating == 0) && (
              <span className="filterSelectionBG">
                {patient_marketplace('ratings')}: {selectedRating === 0 ? patient_marketplace('unrated') : `${selectedRating} ${patient_marketplace('stars')}`}
                <IoClose
                  size={16}
                  onClick={() => {
                    setSelectedRating()
                    setSearchTriggered((prev) => !prev)
                  }}
                />
              </span>
            )}
          </Stack>

          {(brandIds?.length > 0 || productForm?.length > 0 || priceRange[0] !== MIN_RANGE || priceRange[1] !== MAX_RANGE || selectedRating || selectedRating == 0) && (
            <span style={{ backgroundColor: '#f04d4a', borderRadius: '50px', padding: '2px 8px', cursor: 'pointer' }}>
              <button
                className="bg-transparent border-0 text-white"
                onClick={() => {
                  setSelectedRating()
                  setBrandIds([])
                  setProductForm([])
                  setPriceRange([MIN_RANGE, MAX_RANGE])
                  setSearchTriggered((prev) => !prev)
                  setSearchText('')
                  setSearchQuery('')
                }}
                style={{ fontSize: 13 }}
              >
                ✖ {patient_marketplace('clear_all')}
              </button>
            </span>
          )}
        </div>
      </Stack>
    </div>
  )
}

export default ProductSearchSection
