export default function ProductCard({ label, icon, imgSrc, onClick }) {
  return (
    <div className="w-100 bg-white shadow-sm" style={{ borderRadius: 8, padding: 24, cursor: 'pointer' }} onClick={onClick}>
      <div className="d-flex justify-content-between align-items-center">
        <h5 className="m-0">{label}</h5>
        {icon ? icon : <img src={imgSrc} alt="category icon" style={{ maxHeight: '45px', borderRadius: 8 }} />}
      </div>
    </div>
  )
}
