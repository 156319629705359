import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { Button, Divider, Stack } from '@mui/material'
import Footer from 'components/Footer'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FaMinus, FaPlus } from 'react-icons/fa'
import TestImage from '../../../assets/images/img-1.jpg'
import { Link, useNavigate } from 'react-router-dom'
import { Form, Col, InputGroup, Row, Card } from 'react-bootstrap'
import { STRIPE_PUBLISHABLE_KEY } from 'constants'
import CartCheckout from './marketplaceProducts/CartCheckout'
import { useApplyCouponCodeMutation, useCheckoutOrderMutation, useGetCartItemsQuery, useRemoveCartItemMutation, useUpdateCartItemQuantityMutation } from '../lib/services/marketplace/nHCartApi'
import emptyCartAnimation from '../pages/marketPlace/products/helper/emptycart-animation.json'
import { toast } from 'react-toastify'
import Lottie from 'lottie-react'
import RingLoading from 'components/lottie/RingLoading'
import CommonCard from './layout/CardCommonLayout'
import { BasePriceIfHasDiscount, DiscountedButton } from '../pages/marketPlace/products/common/ProductPriceComponents'

export default function MarketplaceProductCart() {
  const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY)

  const { t: patient_marketplace } = useTranslation('patient_marketplace')
  const { t: patient_common } = useTranslation('patient_common')
  const navigate = useNavigate()

  const { data: cartItems, isLoading: isCartItemsLoading } = useGetCartItemsQuery({ page: 1, per_page: 4 }, { refetchOnMountOrArgChange: true })
  const { items, cart_total_amount, cart_tax_amount } = cartItems?.data || []

  // States & variables------
  const [couponText, setCouponText] = useState('')
  const [isCouponInvalid, setIsCouponInvalid] = useState(false)
  const [updateCartItems] = useUpdateCartItemQuantityMutation()
  const [removeCartItem] = useRemoveCartItemMutation()
  const [applyCouponCode, { data: couponCodeResp, reset: couponCodeRespReset, error: couponCodeRespError }] = useApplyCouponCodeMutation()
  const [checkoutOrder, { data: checkoutOrderResp, isLoading: isCheckoutLoading }] = useCheckoutOrderMutation()
  const [groupedItems, setGroupedItems] = useState([]) // State to hold grouped items
  const [showPickupMsg, setShowPickupMsg] = useState(false) // State to show Pickup Msg

  // Group items by pickup address
  useEffect(() => {
    if (cartItems?.data?.items) {
      const grouped = items?.reduce((acc, product) => {
        const address = product.product.pickup_address.address_line_1

        if (!acc[address]) {
          acc[address] = []
        }
        acc[address].push(product)

        return acc
      }, {})

      // Convert grouped object to an array
      const groupedArray = Object.keys(grouped).map((address) => ({ address_line_1: address, items: grouped[address] }))
      const uniqueAddresses = new Set(groupedItems?.map((group) => group.address_line_1))
      setShowPickupMsg(uniqueAddresses.size > 1)
      setGroupedItems(groupedArray)
    }
  }, [cartItems, items])

  // Validate coupon text field
  useEffect(() => {
    if (couponText === '' || !couponText) {
      couponCodeRespReset()
    }
  }, [couponText])

  // Checkout responnse after make payment
  useEffect(() => {
    if (checkoutOrderResp && checkoutOrderResp?.status) {
      toast.success(checkoutOrderResp?.message)
    }
  }, [checkoutOrderResp])

  // Handle quantity change for an item in the cart
  const handleQuantityChange = async (product, action) => {
    const updatedQuantity = action === 'increase' ? product.quantity + 1 : product.quantity - 1

    if (updatedQuantity <= 0) {
      const response = await removeCartItem(product?.id)
      toast.success(response?.message || 'Item Removed from cart successfully')
      return
    }

    const payload = { id: product?.id, quantity: updatedQuantity }

    try {
      const response = await updateCartItems(payload).unwrap()
      toast.success(response?.message || 'Cart Update Successfully!')
    } catch (error) {
      toast.error(error?.data?.message)
    }
  }

  // Coupon Code handle func.
  const handleCouponCodeApply = async () => {
    if (couponText && couponText !== '') {
      setIsCouponInvalid(false)
      try {
        const payload = { code: couponText }
        await applyCouponCode(payload).unwrap()
      } catch (error) {
        console.log('Error: ', error)
      }
    } else {
      setIsCouponInvalid(true)
    }
  }

  // Payment handling Fucn.
  const onPaymentSuccess = async (paymentMethod) => {
    try {
      const payload = { payment_method_id: paymentMethod.id }
      if (couponCodeResp?.data) payload.coupon_code = couponCodeResp?.data?.coupon?.code
      await checkoutOrder(payload).unwrap()
      navigate('/patient/marketplace/products/')
    } catch (error) {
      toast.error(error?.data?.message)
    }
  }

  return (
    <div className="figma mt-5">
      <div className="page-wrapper">
        <div className="page-content">
          <div className="container-fluid p-0 p-md-3">
            {/* Title sec... */}
            <div className="page-title-box" style={{ paddingBottom: 8 }}>
              <h4 className="page-title" style={{ fontWeight: 600 }}>
                {patient_common('products')}
              </h4>
            </div>

            {/* Bredcrumb sec... */}
            <ol className="justify-content-end breadcrumb pr-0">
              <li className="breadcrumb-item">{patient_marketplace('marketplace')}</li>
              <li className="breadcrumb-item">{patient_common('product')}</li>
            </ol>

            {isCartItemsLoading ? (
              <CommonCard>
                <div className="col-lg-12 p-0">
                  <div className="d-flex justify-content-center w-100">
                    <RingLoading size={150} />
                  </div>
                </div>
              </CommonCard>
            ) : !cartItems?.data?.items?.length ? (
              <CommonCard>
                <div className="col-lg-12 d-flex align-items-center justify-content-center p-0">
                  <Lottie animationData={emptyCartAnimation} style={{ width: '100%', height: 'auto', maxWidth: 400 }} />
                  <Stack>
                    <h2 className="ml-md-2">{patient_marketplace('empty_cart_title')}</h2>
                    <h5 className="ml-md-2">
                      {patient_marketplace('empty_cart_message_one')} <Link to={'..'}>{patient_marketplace('empty_cart_message_two')} </Link> {patient_marketplace('empty_cart_message_three')}
                    </h5>
                  </Stack>
                </div>
              </CommonCard>
            ) : (
              <>
                {/* Pickup Address sec... */}
                <CommonCard>
                  <Stack className="p-0" gap={2}>
                    <h5 className="m-0" style={{ fontSize: 18, fontWeight: 600 }}>
                      {patient_marketplace('shopping_cart')}
                    </h5>
                    {showPickupMsg && <span className="ml-md-2">{patient_marketplace('total_available_pickup_message')}</span>}
                  </Stack>
                </CommonCard>

                <div className="row">
                  {/* Cart Products sec... */}

                  <div className="col-12 col-md-8" style={{ maxHeight: 700, overflowY: 'auto', scrollbarWidth: 'none' }}>
                    {/* All Products List sec... */}
                    {groupedItems?.map((group, ind) => (
                      <div className="card" key={ind} style={{ borderRadius: 8 }}>
                        <Stack
                          direction={{ md: 'row' }}
                          gap={2}
                          className="align-items-md-center justify-content-between h-100"
                          style={{ backgroundColor: '#F1F5FA', borderRadius: 8, height: 61, padding: '8px 16px' }}
                        >
                          <h5 className="m-0" style={{ fontSize: 16, fontWeight: 600 }}>
                            {patient_marketplace('pickup', { pickup_number: ind + 1 })}
                          </h5>
                          <Stack gap={0.5} className="text-md-right">
                            <span style={{ fontWeight: 600, fontSize: 15 }}>{patient_marketplace('available_for_pickup_at')}</span>
                            <span>{group.address_line_1 || '-'}</span>
                          </Stack>
                        </Stack>

                        {/* list of item */}
                        <div className="card-body p-0">
                          <div className="col-lg-12 p-0">
                            <Stack className="px-md-4">
                              {group && group?.items?.length > 0 ? (
                                group?.items?.map((product) => {
                                  const { base_price, discounted_price, id, name, brand, images } = product?.product

                                  return (
                                    <React.Fragment key={product.id}>
                                      <div className="row m-0 py-4">
                                        <Link to={`/patient/marketplace/products/${id}`} className="col-12 col-md-3">
                                          <div className="item-img-bg-block">
                                            <Card.Img
                                              variant="top"
                                              src={images[0]?.url || TestImage}
                                              alt={`${name}-image`}
                                              width="100%"
                                              height="auto"
                                              style={{ cursor: 'pointer', borderRadius: 10, height: 120 }}
                                            />
                                          </div>
                                        </Link>

                                        {/* Product Details */}
                                        <Stack direction={{ md: 'row' }} className="col-12 col-md-9 align-items-start justify-content-between" style={{ padding: '0 14px' }}>
                                          <Stack gap={0.5}>
                                            <h1 style={{ fontSize: 18, margin: 0, fontWeight: 600 }}>{name}</h1>
                                            <div>
                                              <Link style={{ color: '#303e67', textDecoration: 'underline' }} to={'/patient/marketplace/products'}>
                                                {brand?.name || '-'}
                                              </Link>
                                              <span className="d-inline-block rounded-circle mx-2" style={{ width: 3, height: 3, backgroundColor: '#D9D9D9', padding: 4 }} />
                                              <span>{product.quantity}</span>
                                              {product?.subQuantity && (
                                                <>
                                                  <span className="d-inline-block rounded-circle mx-2" style={{ width: 3, height: 3, backgroundColor: '#D9D9D9', padding: 4 }} />
                                                  <span>{product.subQuantity}</span>
                                                </>
                                              )}
                                            </div>

                                            {/* Price & Discount */}
                                            <div>
                                              {(product?.variant ? product?.variant?.discounted_price : discounted_price) ? (
                                                <div className="d-flex align-items-center mb-1">
                                                  <span className="mr-2" style={{ fontSize: 16, fontWeight: 600 }}>
                                                    ${product?.variant ? product?.variant?.discounted_price : discounted_price}
                                                  </span>
                                                  <BasePriceIfHasDiscount basePrice={product?.variant ? product?.variant?.base_price : base_price} fontSize={14} />
                                                </div>
                                              ) : (
                                                <span className="mr-2" style={{ fontSize: 17, fontWeight: 600 }}>
                                                  ${product?.variant ? product?.variant?.base_price : base_price}
                                                </span>
                                              )}
                                              {(product?.variant ? product?.variant?.discounted_price : discounted_price) && (
                                                <DiscountedButton
                                                  basePrice={product?.variant ? product?.variant?.base_price : base_price}
                                                  discountedPrice={product?.variant ? product?.variant?.discounted_price : discounted_price}
                                                />
                                              )}
                                            </div>
                                          </Stack>

                                          <Stack
                                            direction="row"
                                            gap={1.5}
                                            width={200}
                                            maxWidth={{ md: '30%' }}
                                            className="align-items-center justify-content-between mt-4"
                                            style={{ border: '1px solid #008100', borderRadius: '50px', padding: '10px 24px' }}
                                          >
                                            <button className="bg-transparent border-0" style={{ color: '#008100', padding: '0 4px' }} onClick={() => handleQuantityChange(product, 'decrease')}>
                                              <FaMinus />
                                            </button>

                                            <h6 className="m-0" style={{ fontSize: 16 }}>
                                              {product.quantity}
                                            </h6>

                                            <button className="bg-transparent border-0" style={{ color: '#008100', padding: '0 4px' }} onClick={() => handleQuantityChange(product, 'increase')}>
                                              <FaPlus />
                                            </button>
                                          </Stack>
                                        </Stack>
                                      </div>
                                      {items.length > 1 && <Divider />}
                                    </React.Fragment>
                                  )
                                })
                              ) : (
                                <h5 className="d-flex justify-content-between mb-4 mt-1" style={{ fontSize: 17, fontWeight: 600 }}>
                                  {patient_marketplace('no_data_found')}
                                </h5>
                              )}
                            </Stack>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className="col-12 col-md-4">
                    {/* Promo Code */}
                    <CommonCard>
                      <Stack gap={2} className="col-lg-12 p-0">
                        <h5 className="m-0" style={{ fontSize: 18, fontWeight: 600 }}>
                          {patient_marketplace('promo_code')}
                        </h5>

                        <Stack gap={1}>
                          {/* Promo code input */}
                          <Row
                            className="align-items-center m-0 flex-nowrap"
                            style={{ border: `1px solid ${isCouponInvalid ? 'red' : 'transparent'}`, backgroundColor: '#f1f5fa', borderRadius: '50px', padding: '2px', gap: 8, maxWidth: 800 }}
                          >
                            {/* Specializations Displayed in Input */}
                            <Col md className="p-0">
                              <InputGroup>
                                <Form.Control
                                  type="search"
                                  placeholder={patient_marketplace('enter_promo_code')}
                                  value={couponText}
                                  onChange={(e) => {
                                    setCouponText(e.target.value)
                                    setIsCouponInvalid(false)
                                  }}
                                  style={{ border: 0, borderRadius: '50px', padding: '10px 15px', backgroundColor: '#f1f5fa' }}
                                />
                              </InputGroup>
                            </Col>

                            {/* Search Button */}
                            <span md="auto">
                              <Button
                                className="border-0 text-white text-capitalize"
                                sx={{ width: { xs: 'auto', md: 125 } }}
                                style={{ backgroundColor: '#008100', borderRadius: '50px', padding: '8px 20px' }}
                                onClick={handleCouponCodeApply}
                              >
                                {patient_marketplace(couponCodeResp?.data ? 'applied' : 'apply')}
                              </Button>
                            </span>
                          </Row>

                          {/* Promo code apply status */}
                          {couponCodeResp?.data && (
                            <h5
                              style={{
                                borderRadius: 50,
                                fontWeight: 400,
                                color: '#008100',
                                backgroundColor: '#cce6cc',
                                padding: '4px 10px',
                                fontSize: 14,
                                margin: '10px auto 0',
                                width: 'max-content',
                              }}
                            >
                              {patient_marketplace('discount_success_message', {
                                discount_value: couponCodeResp?.data?.coupon?.discount_value,
                                discount_type: couponCodeResp?.data?.coupon?.discount_type == 'percentage' ? '%' : '$',
                              })}
                            </h5>
                          )}
                          {couponCodeRespError?.data && (
                            <h5 style={{ borderRadius: 50, fontWeight: 400, color: 'red', backgroundColor: '#ffecec', padding: '4px 10px', fontSize: 14, margin: '10px auto 0', width: 'max-content' }}>
                              {couponCodeRespError?.data?.errors?.code || couponCodeRespError?.data?.message}
                            </h5>
                          )}
                        </Stack>
                      </Stack>
                    </CommonCard>

                    {/* Payment Details */}
                    <CommonCard>
                      <Stack gap={2} className="col-lg-12 p-0 text-center">
                        <h5 className="text-left m-0" style={{ fontSize: 18, fontWeight: 600 }}>
                          {patient_marketplace('payment_details')}
                        </h5>

                        <Stack gap={1}>
                          <div className="d-flex align-items-center justify-content-between">
                            <span>{patient_marketplace('cart_total')}</span>
                            <span style={{ fontSize: 15, fontWeight: 500 }}>{'$' + cart_total_amount?.toFixed(2)}</span>
                          </div>
                          <div className="d-flex align-items-center justify-content-between">
                            <span>{patient_marketplace('discount')}</span>
                            <span style={{ fontSize: 15, fontWeight: 500 }}>{couponCodeResp?.status ? '-' + ('$' + couponCodeResp?.data?.discount?.toFixed(2)) : '$' + (0).toFixed(2)}</span>
                          </div>
                          <div className="d-flex align-items-center justify-content-between">
                            <span>{patient_marketplace('tax')}</span>
                            <span style={{ fontSize: 15, fontWeight: 500 }}>${(items?.length ? cart_tax_amount : 0).toFixed(2)}</span>
                          </div>
                          <Divider sx={{ my: 0.5 }} />
                          <div className="d-flex align-items-center justify-content-between">
                            <h5 className="m-0" style={{ fontSize: 18, fontWeight: 600 }}>
                              {patient_marketplace('to_pay')}
                            </h5>
                            <span style={{ fontSize: 18, fontWeight: 600 }}>
                              {couponCodeResp?.status
                                ? '$' + (couponCodeResp?.data?.new_total + (items?.length ? cart_tax_amount : 0))?.toFixed(2)
                                : '$' + (+(cart_total_amount + (items?.length ? cart_tax_amount : 0)))?.toFixed(2)}
                            </span>
                          </div>
                          <Divider sx={{ my: 0.5 }} />
                        </Stack>

                        {/* Pay Button */}
                        <Elements stripe={stripePromise}>
                          <CartCheckout onPaymentSuccess={onPaymentSuccess} isCheckoutLoading={isCheckoutLoading} />
                        </Elements>
                      </Stack>
                    </CommonCard>
                  </div>
                </div>
              </>
            )}
          </div>
          <Footer />
        </div>
      </div>
    </div>
  )
}
