import React, { useState } from 'react'
import { Row, Col, Form, InputGroup, Card } from 'react-bootstrap'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'
import { IoClose, IoStar } from 'react-icons/io5'
import { useSpecialityListQuery } from 'v2/src/lib/services/provider/providerApi'
import { useTranslation } from 'react-i18next'
import { FaCheck } from 'react-icons/fa6'
import { constant } from 'v2/src/pages/marketPlace/products/productConstant'
import { IconButton, Stack } from '@mui/material'
import 'v2/src/pages/marketPlace/marketplace.css'

const { ratingOptions } = constant

const SearchSection = ({ selectedRating, setSelectedRating, handleRatingSelect, specialization, setSpecializations, setSearchTriggered, searchText, setSearchText }) => {
  const { t: patient_marketplace } = useTranslation('patient_marketplace')

  const [isOpen, setIsOpen] = useState(false)
  const [isRatingsOpen, setIsRatingsOpen] = useState(false)

  const { data: specialityListData } = useSpecialityListQuery()

  const handleSpecializationSelect = (id) => {
    setSpecializations((prev) => (prev.includes(id) ? prev.filter((s) => s !== id) : [...prev, id]))
  }

  return (
    <Stack gap={2}>
      <Row className="align-items-center m-0" style={{ border: '1px solid #1b181830', borderRadius: '50px', padding: '2px', gap: 8 }}>
        {/* Specialization Input with Dropdown */}
        <div style={{ position: 'relative', display: 'inline-block' }}>
          <div
            style={{
              backgroundColor: '#f1f5fa',
              color: '#000',
              borderRadius: '50px',
              marginLeft: '2px',
              minWidth: '180px',
              padding: '8px 16px',
              display: 'flex',
              justifyContent: 'space-between',
              cursor: 'pointer',
              alignItems: 'center',
            }}
            onClick={() => setIsOpen(!isOpen)}
          >
            <span> {patient_marketplace('specialization')}</span>
            <span>{isOpen ? <FaChevronUp /> : <FaChevronDown />}</span>
          </div>

          {isOpen && (
            <ul className="filterDropdownMenuStyle">
              {specialityListData?.data?.map((option, index) => (
                <li
                  key={index}
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: '10px',
                    cursor: 'pointer',
                    marginBottom: '5px',
                    width: '100%',
                    backgroundColor: specialization.includes(option?.name) ? '#b3c0e314' : '',
                  }}
                  onClick={() => handleSpecializationSelect(option?.id)}
                >
                  {option?.name}
                  {specialization.includes(option.id) && (
                    <IconButton sx={{ padding: 0 }}>
                      <FaCheck size={14} color="#008100" />
                    </IconButton>
                  )}
                </li>
              ))}
            </ul>
          )}
        </div>

        {/* Specializations Displayed in Input */}
        <Col md className="p-0">
          <InputGroup>
            <Form.Control
              type="text"
              placeholder={patient_marketplace('search_service')}
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value) // Allow typing
              }}
              onFocus={() => setIsOpen(false)} // Close dropdown when typing
              style={{ border: 0, borderRadius: '50px', padding: '10px 15px' }}
            />
          </InputGroup>
        </Col>

        {/* Search Button */}
        <span md="auto">
          <button className="border-0 text-white py-2 px-4" style={{ backgroundColor: '#008100', borderRadius: '50px', width: 125 }} onClick={() => setSearchTriggered((prev) => !prev)}>
            {patient_marketplace('search')}
          </button>
        </span>
      </Row>

      {/* Filters */}
      <Stack gap={1}>
        <div className="d-flex align-items-center" style={{ gap: 12 }}>
          <span auto>
            <span style={{ fontWeight: 500 }}>{patient_marketplace('filter_by')}:</span>
          </span>

          {/* Ratings filter */}
          <span md="auto" style={{ position: 'relative' }}>
            <button className="filterDropdownStyle" onClick={() => setIsRatingsOpen(!isRatingsOpen)}>
              <span>{patient_marketplace('ratings')}</span>
              <FaChevronDown style={{ transform: isRatingsOpen ? 'rotate(180deg)' : 'rotate(0deg)', transition: 'transform 0.3s ease-in-out' }} />
            </button>

            {/* Ratings Dropdown - Custom Card */}
            {isRatingsOpen && (
              <Card className="filterDropdownMenuStyle rounded-0">
                <ul style={{ listStyle: 'none', padding: '0', margin: '0' }}>
                  {ratingOptions.map((option) => (
                    <li
                      key={option.value}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        padding: '12px',
                        cursor: 'pointer',
                        backgroundColor: '#FAFBFF',
                        transition: 'background-color 0.3s ease',
                      }}
                      onClick={() => handleRatingSelect(option.value)}
                    >
                      <div>
                        <span style={{ marginRight: '10px', fontWeight: 500, fontSize: 15 }}>{option.label}</span>
                        {[...Array(5)].map((_, i) => (
                          <IoStar key={i} color={i < option.value ? '#FFCA22' : '#D9D9D9'} style={{ marginRight: '4px' }} size={20} />
                        ))}
                      </div>
                      {selectedRating == option.value && (
                        <span className="text-success">
                          <FaCheck style={{ color: '#008100' }} />
                        </span>
                      )}
                    </li>
                  ))}
                </ul>
              </Card>
            )}
          </span>
        </div>

        {/* Filters list */}
        <div className="d-flex flex-wrap align-items-center justify-content-between" style={{ gap: 12, marginLeft: '4.5rem' }}>
          <Stack direction={{ md: 'row' }} gap={2}>
            {specialization?.length > 0 && (
              <span className="filterSelectionBG">
                {`${patient_marketplace('specialization')}: ${specialityListData?.data
                  .filter((item) => specialization.includes(item.id))
                  .map((item) => item.name)
                  .join(', ')}`}
                <IoClose
                  size={16}
                  onClick={() => {
                    setSpecializations([])
                    setSearchTriggered((prev) => !prev)
                  }}
                />
              </span>
            )}

            {(selectedRating || selectedRating == 0) && (
              <span className="filterSelectionBG">
                {patient_marketplace('ratings')}: {selectedRating === 0 ? patient_marketplace('unrated') : `${selectedRating} ${patient_marketplace('stars')}`}
                <IoClose
                  size={16}
                  onClick={() => {
                    setSelectedRating()
                    setSearchTriggered((prev) => !prev)
                  }}
                />
              </span>
            )}
          </Stack>

          {(selectedRating || selectedRating !== undefined || specialization.length > 0) && (
            <span style={{ backgroundColor: '#f04d4a', borderRadius: '50px', padding: '2px 8px', cursor: 'pointer' }}>
              <button
                className="bg-transparent border-0 text-white"
                onClick={() => {
                  setSelectedRating()
                  setSpecializations([])
                  setSearchText('')
                  setSearchTriggered((prev) => !prev)
                }}
                style={{ fontSize: 13 }}
              >
                ✖ {patient_marketplace('clear_all')}
              </button>
            </span>
          )}
        </div>
      </Stack>
    </Stack>
  )
}

export default SearchSection
