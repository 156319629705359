// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Marketplace Landing styles */

.carousel .carousel-item img {
  object-fit: cover !important;
}

.carousel .carousel-indicators {
  bottom: -30px;
}

.carousel .carousel-indicators button {
  width: 10px;
  height: 10px;
  background-color: #d9d9d9;
  border-radius: 50%;
  margin: 5px;
  padding: 5px;
  border: 0;
}

.carousel .carousel-indicators button.active {
  background-color: green;
}


/* Common styles */
.filterDropdownStyle {
  background-color: #f1f5fa;
  color: #000;
  border: none;
  border-radius: 50px;
  padding: 4px 16px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  align-items: center;
  gap: 32px;
}

.filterDropdownMenuStyle {
  background-color: #fff;
  z-index: 1000;
  position: absolute;
  padding: 0;
  border-radius: 12px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  top: 100%;
  left: 0;
  margin-top: 8px;
  width: 250px;
}

.filterSelectionBG {
  background-color: #fde0e4;
  color: #f04d4a;
  border: none;
  border-radius: 50px;
  padding: 2px 10px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  align-items: center;
  gap: 14px;
  font-size: 13px;
}`, "",{"version":3,"sources":["webpack://./src/v2/src/pages/marketPlace/marketplace.css"],"names":[],"mappings":"AAAA,+BAA+B;;AAE/B;EACE,4BAA4B;AAC9B;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,yBAAyB;EACzB,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,SAAS;AACX;;AAEA;EACE,uBAAuB;AACzB;;;AAGA,kBAAkB;AAClB;EACE,yBAAyB;EACzB,WAAW;EACX,YAAY;EACZ,mBAAmB;EACnB,iBAAiB;EACjB,aAAa;EACb,8BAA8B;EAC9B,eAAe;EACf,mBAAmB;EACnB,SAAS;AACX;;AAEA;EACE,sBAAsB;EACtB,aAAa;EACb,kBAAkB;EAClB,UAAU;EACV,mBAAmB;EACnB,2CAA2C;EAC3C,SAAS;EACT,OAAO;EACP,eAAe;EACf,YAAY;AACd;;AAEA;EACE,yBAAyB;EACzB,cAAc;EACd,YAAY;EACZ,mBAAmB;EACnB,iBAAiB;EACjB,aAAa;EACb,8BAA8B;EAC9B,eAAe;EACf,mBAAmB;EACnB,SAAS;EACT,eAAe;AACjB","sourcesContent":["/* Marketplace Landing styles */\n\n.carousel .carousel-item img {\n  object-fit: cover !important;\n}\n\n.carousel .carousel-indicators {\n  bottom: -30px;\n}\n\n.carousel .carousel-indicators button {\n  width: 10px;\n  height: 10px;\n  background-color: #d9d9d9;\n  border-radius: 50%;\n  margin: 5px;\n  padding: 5px;\n  border: 0;\n}\n\n.carousel .carousel-indicators button.active {\n  background-color: green;\n}\n\n\n/* Common styles */\n.filterDropdownStyle {\n  background-color: #f1f5fa;\n  color: #000;\n  border: none;\n  border-radius: 50px;\n  padding: 4px 16px;\n  display: flex;\n  justify-content: space-between;\n  cursor: pointer;\n  align-items: center;\n  gap: 32px;\n}\n\n.filterDropdownMenuStyle {\n  background-color: #fff;\n  z-index: 1000;\n  position: absolute;\n  padding: 0;\n  border-radius: 12px;\n  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);\n  top: 100%;\n  left: 0;\n  margin-top: 8px;\n  width: 250px;\n}\n\n.filterSelectionBG {\n  background-color: #fde0e4;\n  color: #f04d4a;\n  border: none;\n  border-radius: 50px;\n  padding: 2px 10px;\n  display: flex;\n  justify-content: space-between;\n  cursor: pointer;\n  align-items: center;\n  gap: 14px;\n  font-size: 13px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
