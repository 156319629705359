import { useTranslation } from 'react-i18next'
import Pagination from 'react-js-pagination'

const CustomTable = ({ columns, rows, total, page, setPage, perPage, handleRowClick, isClickableRow = false }) => {
  const { t: common } = useTranslation('common')

  return (
    <div className="bg-white pt-3">
      <div style={{ overflowX: 'auto' }}>
        <table className="table" style={{ minWidth: '100%' }}>
          <thead style={{ background: '#f1f4f9' }}>
            <tr>
              {columns?.map((column, index) => (
                <th className="fw-bold" key={index}>
                  {column?.label}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {rows?.length ? (
              rows?.map((item, index) => (
                <tr key={index} onClick={() => isClickableRow && handleRowClick(item?.id)} style={{ cursor: isClickableRow ? 'pointer' : '' }}>
                  {columns?.map((cell, i) => (
                    <td key={i} className="fw-bold text-body">
                      {cell?.render ? cell?.render(item, index) : item[cell?.id]}
                    </td>
                  ))}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={columns?.length} style={{ margin: 'auto', textAlign: 'center' }}>
                  {common('no_records_found')}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {total > 0 && (
        <div className="d-flex justify-content-center mt-3 ">
          <Pagination activePage={page} itemsCountPerPage={perPage} totalItemsCount={total} pageRangeDisplayed={5} itemClass="page-item" linkClass="page-link float-center" onChange={setPage} />
        </div>
      )}
    </div>
  )
}

export default CustomTable
