import { nHApi } from 'v2/src/lib/services/public/nHApi'

export const nHServiceApi = nHApi.injectEndpoints({
  endpoints: (builder) => ({
    // ------------------- Institutions----------------
    // Get Institutions
    getInstitutions: builder.query({
      query: ({ params }) => {
        // Check if params has any entries
        const queryString = params && params.toString() ? `?relations[]=provider&${params.toString()}` : '?relations[]=provider'
        return { url: `/institutions${queryString}` }
      },
    }),

    // ------------------- Services----------------
    // Add services
    postService: builder.mutation({
      query: ({ formData }) => {
        return { url: '/service', method: 'POST', body: formData }
      },
      invalidatesTags: ['services'],
    }),

    // Update services
    updateService: builder.mutation({
      query: ({ formData, id }) => {
        return { url: `/service/${id}`, method: 'POST', body: formData }
      },
    }),

    // Get services
    getServiceList: builder.query({
      query: (params) => {
        return { url: `/services?${params.toString()}` }
      },
      providesTags: ['services'],
    }),

    // Get A single Service
    getSingleService: builder.query({
      query: ({ id }) => {
        const relations = ['practice']
        const relationsParam = relations.map((relation) => `relations[]=${encodeURIComponent(relation)}`).join('&')
        return { url: `/service/${id}?${relationsParam}` }
      },
    }),
  }),
})

export const { useLazyGetInstitutionsQuery, usePostServiceMutation, useUpdateServiceMutation, useLazyGetServiceListQuery, useLazyGetSingleServiceQuery } = nHServiceApi
