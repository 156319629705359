// import { NavLink, useLocation, useNavigate } from 'react-router-dom'
// import { APP_ENV, ENABLE_MY_SOCIAL, NIU_SOCIAL_FE_URL } from '../../../constants'
// import useLogout from '../../../hooks/useLogout'
// import { useLogoutMutation } from 'v2/src/lib/services/auth/authApi'
// import Swal from 'sweetalert2'
// import { useEffect, useState } from 'react'
// import useAuth from '../../../hooks/useAuth'
// import useAxiosPrivate from '../../../hooks/useAxiosPrivate'
// import useGetPatientDetails from '../../../hooks/api/useGetPatientDetails'
// import { useSelector } from 'react-redux'
// import Cookies from 'js-cookie'
// import { useTranslation } from 'react-i18next'
// import { redirectToNiuSocial } from '../../../utils'
// //---------------------------------------------------------

// function PatientSideNav({ openSideNav, openSideIcons, removePfp }) {
//   const location = useLocation()
//   const navigate = useNavigate()
//   const logout = useLogout()
//   const [openSideNav_delayed, setOpenSideNav_delayed] = useState(openSideNav)
//   const name = Cookies.get('name') || 'Welcome'
//   const email = Cookies.get('email')
//   const has_insurance = Cookies.get('has_insurance')
//   const [subscribed, setSubscribed] = useState()
//   const sessionuser = { name: name, email: email }
//   const { auth } = useAuth()
//   const [isLoading, setIsLoading] = useState(true)
//   const axiosPrivate = useAxiosPrivate()
//   const { t: patient_common } = useTranslation('patient_common')
//   const { t: Wallet } = useTranslation('wallet')
//   const [fLogout, { isLoading: isSigningOut }] = useLogoutMutation()

//   let mySocialMenuCls = 'disable-menu'
//   if (ENABLE_MY_SOCIAL) {
//     mySocialMenuCls = ''
//   }

//   const [activeNavItem, setActiveNavItem] = useState('virtualvisit')
//   const patientStateData = useSelector((state) => state.patient)
//   const [subItemVisible, setSubItemVisible] = useState(false)
//   const { getPatientDetails } = useGetPatientDetails()

//   async function logoutCurrentUser() {
//     //const logOutResp = await fLogout().unwrap();console.log('logOutResp', logOutResp);
//     // await axiosPrivate.post('signOut', { Email: auth.email }).then((res) => {
//     //   // const { StatusCode, Data: data = [], Message } = res.data;
//     // })
//   }

//   function handleLogout(e) {
//     e.preventDefault()
//     logout()
//     logoutCurrentUser()
//     navigate('/login', { replace: true })
//   }

//   useEffect(() => {
//     const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms))
//     const handleClick = async () => {
//       //console.log(openSideNav)
//       if (openSideNav === true) {
//         await delay(400)
//         setOpenSideNav_delayed(openSideNav)
//       } else {
//         setOpenSideNav_delayed(openSideNav)
//       }
//     }
//     handleClick()
//   }, [openSideNav])

//   // useEffect(() => {
//   //   const controller = new AbortController()
//   //   function getPatientDetails() {
//   //     axiosPrivate
//   //       .post(
//   //         'getPatientDetails',
//   //         {
//   //           Email: auth.email || Cookies.get('email'),
//   //         },
//   //         { signal: controller.signal }
//   //       )
//   //       .then((res) => {
//   //         setIsLoading(false)
//   //         console.log('res', res)
//   //         setSubscribed(parseInt(res?.data.Data[0].subscription_plan) > 0)
//   //         Cookies.set('has_insurance', res.data.Data[0].has_insurance)
//   //       })
//   //       .catch((error) => {
//   //         console.error(error)
//   //       })
//   //   }
//   //   getPatientDetails()
//   // }, [])

//   const handleSubItem = () => {
//     setSubItemVisible(!subItemVisible)
//   }

//   useEffect(() => {
//     if (Object.keys(patientStateData).length) {
//       setIsLoading(false)
//       setSubscribed(parseInt(patientStateData?.Data[0].subscription_plan) > 0)
//       Cookies.set('has_insurance', patientStateData?.Data[0].has_insurance)
//     } else {
//       getPatientDetails({ apiParams: { Email: auth.email || Cookies.get('email') } })
//     }
//   }, [patientStateData])

//   const navItems = [
//     { path: 'virtualvisit', icon: 'mdi mdi-video', label: patient_common('virtual_visit'), navItemCls: activeNavItem === 'virtualvisit' ? 'activeNavItem' : '', onClick: '' },
//     // { path: 'ask-dr-coco', icon: 'mdi mdi-chat', label: patient_common('ask_dr_coco'), navItemCls: activeNavItem === 'ask-dr-coco' ? 'activeNavItem' : '', onClick: '' },
//     { path: 'appointments', icon: 'mdi mdi-calendar-text', label: patient_common('my_appointments'), navItemCls: activeNavItem === 'appointments' ? 'activeNavItem' : '', onClick: '' },
//     { path: '#', icon: 'mdi mdi-account', label: patient_common('my_social'), navItemCls: mySocialMenuCls, onClick: redirectToNiuSocial },
//     // { path: 'marketplace', icon: 'dripicons-medical', label: patient_common('marketplace'), navItemCls: activeNavItem === 'marketplace' ? 'activeNavItem' : '', onClick: '' },
//     {
//       path: 'marketplace',
//       icon: 'dripicons-medical',
//       label: patient_common('marketplace'),
//       // navItemCls: activeNavItem === 'marketplace' ? 'activeNavItem' : '',
//       onClick: handleSubItem,
//       subItems: [
//         {
//           path: 'marketplace',
//           label: patient_common('services'),
//           navItemCls: activeNavItem === 'marketplace' ? 'activeNavItem' : '',
//           onClick: '',
//         },
//         { path: 'marketplace/products', navItemCls: activeNavItem === 'marketplace' ? 'activeNavItem' : '', label: patient_common('products'), onClick: '' },
//       ],
//     },
//     { path: 'subscription', icon: 'mdi mdi-credit-card', label: patient_common('subscription_payments'), navItemCls: activeNavItem === 'subscription' ? 'activeNavItem' : '', onClick: '' },
//     /*{ path: 'insurance', icon: 'mdi mdi-home-plus', label: patient_common('insurance'), navItemCls: activeNavItem === 'insurance' ? 'activeNavItem' : '', onClick: '' },*/
//     { path: 'wallet', icon: 'mdi mdi-wallet', label: Wallet('wallet'), navItemCls: activeNavItem === 'wallet' ? 'activeNavItem' : '', onClick: '' },
//     { path: 'profile', icon: 'dripicons-user', label: patient_common('profile'), navItemCls: activeNavItem === 'profile' ? 'activeNavItem' : '', onClick: '' },
//   ]

//   useEffect(() => {
//     if (location) {
//       const matchingNavItem = navItems.find((item) => location.pathname.includes(item.path))

//       if (matchingNavItem) {
//         setActiveNavItem(matchingNavItem.path)
//       }
//     }
//   }, [location])
//   // useEffect(() => {
//   //   if (activeNavItem) {
//   //     //console.log(activeNavItem)
//   //   }
//   // }, [activeNavItem])

//   const lnkLogoutJsx = (
//     <div className="logoutDiv row" style={{ marginLeft: -18 }}>
//       <ul className="metismenu left-sidenav-menu">
//         <li>
//           {
//             <NavLink onClick={handleLogout.bind(this)}>
//               <i className="fas fa-sign-out-alt"></i>
//               {openSideNav_delayed ? <span>{patient_common('logout')}</span> : <span className="d-inline d-sm-none">{patient_common('logout')}</span>}
//             </NavLink>
//           }
//         </li>
//       </ul>
//     </div>
//   )
//   //---------------------------------------------------------

//   return (
//     <div
//       className="left-sidenav"
//       // style={{ display: openSideNav ? 'block' : 'none', minWidth: '308px' ,}}
//       style={{
//         display: openSideIcons ? 'block' : 'none',
//         marginTop: '70px',
//         padding: '0px',
//         marginTop: '70px',
//         minWidth: '80px',
//         pxmarginLeft: '-26zpx',
//         width: openSideNav ? '320px' : '78px',
//         zIndex: 10,
//         position: !removePfp ? 'unset' : 'absolute',
//       }}
//     >
//       {/* <div className='topbar-left'>
//         <Link to='/patient' className='logo'>
//           <span>
//             <SideNavLogo />
//           </span>
//         </Link>
//       </div> */}

//       {/* <div className='media figmaSideUser'>
//         <Link className='' href='#'>
//           <img
//             src={`${APP_URL}/assets/images/users/user-1.jpg`}
//             alt='user'
//             className='rounded-circle thumb-md'
//             onError={(e) =>
//               (e.target.src = `${AWS_BUCKET}/assets/images/users/user-1.jpg`)
//             }
//           />
//         </Link>
//         <div className='media-body align-self-center ml-3'>
//           <p className='font-14 font-weight-bold mb-0'>{name}</p>
//           <p className='mb-0 font-12 text-muted'>{email}</p>
//         </div>
//       </div> */}
//       {
//         <ul className="metismenu left-sidenav-menu">
//           {navItems.map((item) => (
//             <li key={item.path}>
//               <NavLink to={item.path} className={item.navItemCls} onClick={item.onClick}>
//                 <i className={item.icon}></i>
//                 {openSideNav_delayed ? <span>{item.label}</span> : <span className="d-inline d-sm-none">{item.label}</span>}
//               </NavLink>

//               {/* {item.subItems && subItemVisible && (
//                 <ul className="submenu">
//                   {item.subItems.map((subItem) => (
//                     <li key={subItem.path}>
//                       <NavLink to={subItem.path} className={activeNavItem === subItem.path ? 'activeNavItem' : ''} onClick={subItem.onClick}>
//                         <i className={item.icon}></i>
//                         {subItem.label}
//                       </NavLink>
//                     </li>
//                   ))}
//                 </ul>
//               )}
//                */}
//               {item.subItems && subItemVisible && (
//                 <ul className="submenu">
//                   {item.subItems.map((subItem) => {
//                     const isDisabled = subItem.label === 'products' // Check if subItem.label is "product"
//                     return (
//                       <li key={subItem.path} className={isDisabled ? 'disabled' : ''}>
//                         <NavLink
//                           to={isDisabled ? '#' : subItem.path}
//                           className={isDisabled ? 'disabled-link' : activeNavItem === subItem.path ? 'activeNavItem' : ''}
//                           onClick={(e) => {
//                             if (isDisabled) {
//                               e.preventDefault() // Prevent navigation
//                               Swal.fire('Access Denied', 'You cannot access this item.', 'error')
//                             }
//                           }}
//                         >
//                           <i className={subItem.icon}></i>
//                           {subItem.label}
//                         </NavLink>
//                       </li>
//                     )
//                   })}
//                 </ul>
//               )}
//             </li>
//           ))}
//         </ul>
//       }
//       {/* <div className='virtualTourSide'>

//         <button
//           disabled={isLoading}
//           type='button'
//           className={`btn ${(has_insurance==='true'||subscribed)?"btn-success":"btn-outline-success"}  btn-round waves-effect waves-light figmaBigButton`}
//           onClick={
//             () => {
//               if (has_insurance==='true'||subscribed){
//                 navigate('/virtualvisit')
//               }
//               else if (has_insurance==='false'){

//                 Swal.fire({
//                   html:
//                   `
//                   Access Virtual Visits by uploading your updated <a href='/patient/insurance'>Insurance</a>  or by <a href='/patient/subscription/plans'>Subscribing.</a>

//                   `})
//                 }
//             }}
//         >
//           {(isLoading)?"Loading...":"Start Your Virtual Visit"}
//         </button>
//       </div> */}
//       {/* {location?.pathname === '/patient/virtualvisit' ? (
//         <div
//           className='spacetop alert alert-warning alert-warning-shadow mb-0 alert-dismissible fade show'
//           role='alert'
//         >
//           <button
//             type='button'
//             className='close'
//             data-dismiss='alert'
//             aria-label='Close'
//           >
//             <span aria-hidden='true'>
//               <i className='mdi mdi-close'></i>
//             </span>
//           </button>
//           Sorry, we are unavailable for virtual visits from 12:00 Am to 8:00 Am.
//           Please call 808-888-4800 and select option 4 to talk to a provider
//         </div>
//       ) : null} */}
//       {lnkLogoutJsx}
//     </div>
//   )
// }

// export default PatientSideNav
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import { APP_ENV, ENABLE_MY_SOCIAL, NIU_SOCIAL_FE_URL } from '../../../constants'
import useLogout from '../../../hooks/useLogout'
import { useLogoutMutation } from 'v2/src/lib/services/auth/authApi'
import Swal from 'sweetalert2'
import { useEffect, useState } from 'react'
import useAuth from '../../../hooks/useAuth'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'
import useGetPatientDetails from '../../../hooks/api/useGetPatientDetails'
import { useSelector } from 'react-redux'
import Cookies from 'js-cookie'
import { useTranslation } from 'react-i18next'
import { redirectToNiuSocial } from '../../../utils'

function PatientSideNav({ openSideNav, openSideIcons, removePfp }) {
  const location = useLocation()
  const navigate = useNavigate()
  const logout = useLogout()
  const [openSideNav_delayed, setOpenSideNav_delayed] = useState(openSideNav)
  const [isLoading, setIsLoading] = useState(true)
  const [subscribed, setSubscribed] = useState()
  const [subItemVisible, setSubItemVisible] = useState(false)
  const [activeNavItem, setActiveNavItem] = useState('virtualvisit')
  const [activeSubNavItem, setActiveSubNavItem] = useState('')

  const name = Cookies.get('name') || 'Welcome'
  const email = Cookies.get('email')
  const has_insurance = Cookies.get('has_insurance')
  const sessionuser = { name: name, email: email }
  const { auth } = useAuth()
  const axiosPrivate = useAxiosPrivate()
  const { t: patient_common } = useTranslation('patient_common')
  const { t: Wallet } = useTranslation('wallet')
  const [fLogout, { isLoading: isSigningOut }] = useLogoutMutation()

  let mySocialMenuCls = 'disable-menu'
  if (ENABLE_MY_SOCIAL) {
    mySocialMenuCls = ''
  }

  const patientStateData = useSelector((state) => state.patient)
  const { getPatientDetails } = useGetPatientDetails()

  async function logoutCurrentUser() {
    //const logOutResp = await fLogout().unwrap();console.log('logOutResp', logOutResp);
    // await axiosPrivate.post('signOut', { Email: auth.email }).then((res) => {
    //   // const { StatusCode, Data: data = [], Message } = res.data;
    // })
  }

  function handleLogout(e) {
    e.preventDefault()
    logout()
    logoutCurrentUser()
    navigate('/login', { replace: true })
  }

  useEffect(() => {
    const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms))
    const handleClick = async () => {
      if (openSideNav === true) {
        await delay(400)
        setOpenSideNav_delayed(openSideNav)
      } else {
        setOpenSideNav_delayed(openSideNav)
      }
    }
    handleClick()
  }, [openSideNav])

  useEffect(() => {
    if (Object.keys(patientStateData).length) {
      setIsLoading(false)
      setSubscribed(parseInt(patientStateData?.Data[0].subscription_plan) > 0)
      Cookies.set('has_insurance', patientStateData?.Data[0].has_insurance)
    } else {
      getPatientDetails({ apiParams: { Email: auth.email || Cookies.get('email') } })
    }
  }, [patientStateData])

  const handleSubItem = (path) => {
    setActiveNavItem(path) // Set the active class on the "Marketplace" item
    const currentState = subItemVisible[path]
    setSubItemVisible({
      ...subItemVisible,
      [path]: !currentState, // Toggle visibility of the submenu
    })
  }

  useEffect(() => {
    if (location) {
      const matchingNavItem = navItems.find((item) => location.pathname.includes(item.path))

      if (matchingNavItem) {
        setActiveNavItem(matchingNavItem.path)
        // Check if it has subitems and set the active subitem
        if (matchingNavItem.subItems) {
          const matchingSubNavItem = matchingNavItem.subItems.find((subItem) => location.pathname.includes(subItem.path))
          if (matchingSubNavItem) {
            setActiveSubNavItem(matchingSubNavItem.path)
          } else {
            setActiveSubNavItem('')
          }
        } else {
          setActiveSubNavItem('')
        }
      }
    }
  }, [location])

  const navItems = [
    { path: 'virtualvisit', icon: 'mdi mdi-video', label: patient_common('virtual_visit'), navItemCls: activeNavItem === 'virtualvisit' ? 'activeNavItem' : '', onClick: '' },
    { path: 'appointments', icon: 'mdi mdi-calendar-text', label: patient_common('my_appointments'), navItemCls: activeNavItem === 'appointments' ? 'activeNavItem' : '', onClick: '' },
    { path: '#', icon: 'mdi mdi-account', label: patient_common('my_social'), navItemCls: mySocialMenuCls, onClick: redirectToNiuSocial },
    {
      path: 'marketplace',
      icon: 'dripicons-medical',
      label: patient_common('marketplace'),
      navItemCls: activeNavItem === 'marketplace' ? 'activeNavItem' : '',
      onClick: () => handleSubItem('marketplace'),
      subItems: [
        { path: 'marketplace/services', icon: 'mdi mdi-cogs', label: patient_common('services'), navItemCls: activeSubNavItem === 'marketplace/services' ? 'activeNavItem' : '', onClick: '' },
        { path: 'marketplace/products', icon: 'mdi mdi-package', label: patient_common('products'), navItemCls: activeSubNavItem === 'marketplace/products' ? 'activeNavItem' : '', onClick: '' },
      ],
    },
    { path: 'subscription', icon: 'mdi mdi-credit-card', label: patient_common('subscription_payments'), navItemCls: activeNavItem === 'subscription' ? 'activeNavItem' : '', onClick: '' },
    { path: 'wallet', icon: 'mdi mdi-wallet', label: Wallet('wallet'), navItemCls: activeNavItem === 'wallet' ? 'activeNavItem' : '', onClick: '' },
    { path: 'profile', icon: 'dripicons-user', label: patient_common('profile'), navItemCls: activeNavItem === 'profile' ? 'activeNavItem' : '', onClick: '' },
  ]

  const lnkLogoutJsx = (
    <div className="logoutDiv row" style={{ marginLeft: -18 }}>
      <ul className="metismenu left-sidenav-menu">
        <li>
          <NavLink onClick={handleLogout.bind(this)}>
            <i className="fas fa-sign-out-alt"></i>
            {openSideNav_delayed ? <span>{patient_common('logout')}</span> : <span className="d-inline d-sm-none">{patient_common('logout')}</span>}
          </NavLink>
        </li>
      </ul>
    </div>
  )

  return (
    <div
      className="left-sidenav"
      style={{
        display: openSideIcons ? 'block' : 'none',
        marginTop: '70px',
        padding: '0px',
        marginTop: '70px',
        minWidth: '80px',
        pxmarginLeft: '-26zpx',
        width: openSideNav ? '320px' : '78px',
        zIndex: 10,
        position: !removePfp ? 'unset' : 'absolute',
      }}
    >
      <ul className="metismenu left-sidenav-menu">
        {navItems.map((item) => (
          <li key={item.path}>
            <NavLink to={item.path} className={item.navItemCls} onClick={item.onClick}>
              <i className={item.icon}></i>
              {openSideNav_delayed ? <span>{item.label}</span> : <span className="d-inline d-sm-none">{item.label}</span>}
            </NavLink>

            {item.subItems && subItemVisible[item.path] && (
              <ul className="submenu">
                {item.subItems.map((subItem) => {
                  const isDisabled = subItem.label === 'products' // Check if subItem.label is "product"
                  return (
                    <li key={subItem.path} className={isDisabled ? 'disabled' : ''}>
                      <NavLink
                        to={isDisabled ? '#' : subItem.path}
                        className={activeSubNavItem === subItem.path ? 'activeNavItem' : ''}
                        onClick={(e) => {
                          if (isDisabled) {
                            e.preventDefault()
                            Swal.fire('Access Denied', 'You cannot access this item.', 'error')
                          } else {
                            setActiveSubNavItem(subItem.path) // Set active class on subitem
                          }
                        }}
                      >
                        <i className={subItem.icon}></i>
                        {/* {subItem.label} */}
                        {openSideNav_delayed ? <span>{subItem.label}</span> : <span className="d-inline d-sm-none">{subItem.label}</span>}
                      </NavLink>
                    </li>
                  )
                })}
              </ul>
            )}
          </li>
        ))}
      </ul>

      {lnkLogoutJsx}
    </div>
  )
}

export default PatientSideNav
