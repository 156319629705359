// import { Typography } from '@mui/material'

export default function CommonCard({ children, cardStyle, bodyStyle }) {
  return (
    <div className="card" style={{ ...cardStyle }}>
      <div className="card-body p-3" style={{ ...bodyStyle }}>
        {children}
      </div>
    </div>
  )
}

// export function CommonTypography({ fontSize, fontWeight, content, variant, classes, sx }) {
//   return (
//     <Typography variant={variant} className={classes} style={{ fontSize, fontWeight }} sx={{ ...sx }} fontFamily="inherit" letterSpacing="normal">
//       {content}
//     </Typography>
//   )
// }
