import React, { useEffect, useState } from 'react'
import { CardLongItem } from 'components/cards/Card'
import styled from '@emotion/styled'
import { Link, useNavigate, useParams } from 'react-router-dom'
import RingLoading from 'components/lottie/RingLoading'
import { useTranslation } from 'react-i18next'
import { useLazyGetInstitutionsQuery } from 'v2/src/lib/services/marketplace/nHServiceApi'
import { useUserProfileDetailQuery } from 'v2/src/lib/services/profileApi'
import { fGetAUserInf } from 'v2/src/lib/appUtils'
import { Col, InputGroup, Form, Row } from 'react-bootstrap'
import { Stack } from '@mui/material'
import CustomPagination from 'v2/src/components/CustomPagination'
import Defaul_Institute from 'assets/images/marketplace/institutions.png'
import CommonCard from 'v2/src/components/layout/CardCommonLayout'
import Footer from 'components/Footer'

export const StyleWrapper = styled.div`
  .optionListContainer {
    position: sticky;
  }
`
export default function ServicesHomeClinics() {
  const { t: patient_marketplace } = useTranslation('patient_marketplace')
  const { t: patient_virtual_visit } = useTranslation('patient_virtual_visit')

  // states and api's
  const [currentPage, setCurrentPage] = useState(1)
  const [searchText, setSearchText] = useState('')
  // for search button enabled/disabled
  const [getInstitutions, { data: healthClinicData, isLoading: isHealthclinicLoading }] = useLazyGetInstitutionsQuery()

  const navigate = useNavigate()
  let aUData = fGetAUserInf()
  const aUserProfileId = aUData?.details?.profileId
  const { data: patientDetails, loading: patientDetailsLoading } = useUserProfileDetailQuery({ profileId: aUserProfileId })

  // Triggered when the user clicks on the search button to call the API
  const handleSearch = () => {
    const params = new URLSearchParams()
    try {
      if (!!searchText) {
        params.append('search', searchText)
        getInstitutions({ params }).unwrap()
      }
    } catch (error) {
      console.log('Error: ', error)
    }
  }

  // Calls the API to get all institutions when the search input is empty
  useEffect(() => {
    if (!searchText) {
      const params = new URLSearchParams()
      getInstitutions({ params }).unwrap()
    }
  }, [searchText])

  useEffect(() => {
    if (patientDetails?.status) {
      if (!patientDetails.data?.personal_details_completed || !patientDetails.data?.has_approved_identification_document) {
        Swal.fire({
          html: patient_virtual_visit('complete_registration'),
          confirmButtonText: patient_virtual_visit('start_verification'),
        }).then(() => {
          navigate('/patient/profile', { replace: true })
        })
      }
    }
  }, [patientDetails])

  // pagination for products list
  const handlePageChange = (_, value) => {
    setCurrentPage(value) // Update the page on user interaction
  }

  return (
    <div className="figma mt-5">
      <div className="page-wrapper">
        <div className="page-content">
          <div className="container-fluid ">
            <div className="row">
              <div className="col-sm-12">
                <div className="page-title-box">
                  <h4 className="page-title" style={{ fontWeight: 600 }}>
                    {patient_marketplace('services')}
                  </h4>
                </div>
              </div>
            </div>

            {/* Bredcrumb sec... */}
            <ol className="breadcrumb justify-content-end pr-0">
              <li className="breadcrumb-item" style={{ fontSize: 15 }}>
                <Link to={'..'}>{patient_marketplace('marketplace')}</Link>
              </li>
              <li className="breadcrumb-item" style={{ fontSize: 15 }}>
                {patient_marketplace('services')}
              </li>
            </ol>
            <div className="row-lg-12">
              {/* Search and Filters sec... */}
              <CommonCard>
                <Stack gap={2} className="col-lg-12 p-0">
                  <div className="d-flex justify-content-between align-items-center">
                    <h5 className="m-0" style={{ fontSize: 16, fontWeight: 600 }}>
                      {patient_marketplace('search_visit')}
                    </h5>
                  </div>
                  <Row className="align-items-center m-0" style={{ border: '1px solid #E8EBF3', borderRadius: '50px', padding: '2px 3px', gap: 8, maxWidth: 700 }}>
                    {/* Specializations Displayed in Input */}
                    <Col md className="p-0">
                      <InputGroup>
                        <Form.Control
                          type="text"
                          placeholder={patient_marketplace('type_or_search_clinic')}
                          value={searchText}
                          onChange={(e) => {
                            setSearchText(e.target.value) // Allow typing
                          }}
                          className="border-0"
                          style={{ borderRadius: '50px', padding: '10px' }}
                        />
                      </InputGroup>
                    </Col>

                    {/* Search Button */}
                    <span md="auto">
                      <button className={` text-white border-0`} style={{ backgroundColor: '#008100', borderRadius: '50px', padding: '8px ', width: 125 }} onClick={handleSearch}>
                        {patient_marketplace('search')}
                      </button>
                    </span>
                  </Row>
                </Stack>
              </CommonCard>

              {/* Healthcare Clinics sec... */}
              <div className="col-lg-12">
                <div className="row">
                  {isHealthclinicLoading ? (
                    <CardLongItem>
                      <div className="d-flex justify-content-center">
                        <RingLoading size={200} />
                      </div>
                    </CardLongItem>
                  ) : (
                    <>
                      <Stack gap={3} className="bg-white p-3 rounded" style={{ width: '100%' }}>
                        <h5 className="m-0" style={{ fontSize: 15, fontWeight: 600 }}>
                          {patient_marketplace('healthcare_clinics')}{' '}
                        </h5>
                        <div class="row g-4 m-0" style={{ rowGap: 24 }}>
                          {healthClinicData?.status && healthClinicData?.data?.length ? (
                            healthClinicData?.data?.map((clinic) => {
                              const primaryAddress = clinic?.addresses?.find((item) => item.is_primary === 1)
                              return (
                                <div class="card m-0 w-100" style={{ boxShadow: '0px 1px 3px 1px #00000014, 0px 1px 2px 0px #0000001F', cursor: 'pointer' }}>
                                  <Stack
                                    direction="row"
                                    gap={2}
                                    className="p-3"
                                    onClick={() =>
                                      navigate(`./${clinic.id}`, {
                                        state: {
                                          insituteName: clinic.name,
                                        },
                                      })
                                    }
                                  >
                                    <div>
                                      <img id="marketplace-services-card-media" src={Defaul_Institute} alt="Card image cap" style={{ width: 110, height: 110, borderRadius: 4 }} />
                                    </div>
                                    <Stack gap={4} className="justify-content-between w-100 h-100">
                                      <h4 className="m-0" style={{ fontSize: 18, fontWeight: 600 }}>
                                        {clinic?.name}
                                      </h4>
                                      <Stack className="justify-content-between" gap={0.5}>
                                        <div>
                                          <span style={{ width: 60, display: 'inline-block' }}>{patient_marketplace('address')}:</span>{' '}
                                          <span style={{ fontSize: 15, fontWeight: 600 }}>
                                            {primaryAddress ? `${primaryAddress?.address_line_1} ${primaryAddress?.address_line_2}, ${primaryAddress?.city}, ${primaryAddress?.zipcode}` : '-'}
                                          </span>
                                        </div>
                                        <div>
                                          <span style={{ width: 60, display: 'inline-block' }}>{patient_marketplace('experts')}:</span>{' '}
                                          <span style={{ fontSize: 15, fontWeight: 600 }}>
                                            {clinic?.provider_count > 0 ? patient_marketplace('doctors_available_count', { provider_count: clinic?.provider_count }) : '-'}
                                          </span>
                                        </div>
                                      </Stack>
                                    </Stack>
                                  </Stack>
                                </div>
                              )
                            })
                          ) : (
                            <div className="text-center w-100">{healthClinicData?.message}</div>
                          )}
                        </div>
                      </Stack>
                    </>
                  )}
                </div>
              </div>

              {/* Pagination for All */}
              <CustomPagination count={healthClinicData?.meta?.last_page} currentPage={currentPage} handlePageChange={handlePageChange} />
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  )
}
