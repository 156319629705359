import { Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

export default function ViewCartRedierctBox({ cartData }) {
  const { t: patient_marketplace } = useTranslation('patient_marketplace')
  const { items: cartItems, cart_total_amount } = cartData
  const navigate = useNavigate()

  return (
    <div style={{ height: 70 }}>
      <div className="card mt-3 position-fixed" style={{ bottom: 0, left: '50%', zIndex: 1, width: 600, transform: 'translate(-45%,0)', boxShadow: '0 1px 8px -3px' }}>
        <div className="card-body">
          <div className="col-lg-12 p-0 d-flex justify-content-between">
            <div>
              <h4 className="m-0 mb-3" style={{ fontSize: 24, fontWeight: 600 }}>
                {'$' + cart_total_amount}
              </h4>
              {`${cartItems.length} ${cartItems.length > 1 ? patient_marketplace('items_added_to_cart') : patient_marketplace('item_added_to_cart')}`}
            </div>
            <Button
              sx={{ width: { xs: 'auto', md: 180 }, textTransform: 'capitalize' }}
              style={{
                backgroundColor: '#008100',
                border: 'none',
                color: '#fff',
                borderRadius: '50px',
                padding: '6px 20px',
              }}
              onClick={() => navigate('/patient/marketplace/products/cart')}
            >
              {patient_marketplace('view_cart')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
