// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#product-image-preview {
  max-height: 90%;
  overflow: hidden;
  display: flex;
}

#product-image-preview .magnify-container {
  margin: 0 1rem;
  line-height: 1;
}`, "",{"version":3,"sources":["webpack://./src/v2/src/pages/marketPlace/products/ProductImagePreview.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,gBAAgB;EAChB,aAAa;AACf;;AAEA;EACE,cAAc;EACd,cAAc;AAChB","sourcesContent":["#product-image-preview {\n  max-height: 90%;\n  overflow: hidden;\n  display: flex;\n}\n\n#product-image-preview .magnify-container {\n  margin: 0 1rem;\n  line-height: 1;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
