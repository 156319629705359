import { Pagination } from '@mui/material'
import { useTranslation } from 'react-i18next'

export default function CustomPagination({ count, currentPage, handlePageChange }) {
  const { t: patient_common } = useTranslation('patient_common')

  return (
    <>
      {/* Pagination for All products */}
      <div className="d-flex align-items-center">
        <span style={{ textWrap: 'nowrap' }}>
          {patient_common('page_of_pages', {
            currentPage: currentPage,
            totalPages: count,
          })}
        </span>
        <Pagination
          count={count}
          page={currentPage}
          onChange={handlePageChange}
          variant="outlined"
          showFirstButton
          showLastButton
          sx={{
            my: 3,
            width: '100%',
            color: '#008100',
            '& .MuiPagination-ul': {
              justifyContent: 'center',
              '& .MuiPaginationItem-root': {
                color: 'inherit',
                backgroundColor: '#fff',
                fontWeight: 500,
                border: 0,
                padding: '18px 14px',
                boxShadow: '0px 1px 5px -2px rgba(0,0,0,0.6)',
                '&.MuiPaginationItem-root.Mui-selected': { backgroundColor: '#008100', color: '#fff' },
              },
            },
          }}
          shape="rounded"
        />
      </div>
    </>
  )
}
