import { useState } from 'react'
import { Dialog, DialogContent, DialogTitle, IconButton, Stack, styled } from '@mui/material'
import { IoClose } from 'react-icons/io5'
import ReactImageMagnify from 'react-image-magnify'
import '../ProductImagePreview.css'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(3),
  },
}))

export default function ProductImagePreviewModel({ isPreviewOpen, handlePreviewClose, currentProduct }) {
  // Extract image URLs from currentProduct
  const thumbUrls = currentProduct?.images?.map((img) => img?.image_url) || []
  const imageUrls = currentProduct?.images?.map((img) => img?.image_url) || []

  const [isCurrentPreview, setIsCurrentPreview] = useState(imageUrls[0])

  return (
    <BootstrapDialog onClose={handlePreviewClose} aria-labelledby="customized-dialog-title" open={isPreviewOpen} maxWidth="lg" sx={{ '& .MuiDialog-paper': { width: 1240 } }}>
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        {currentProduct?.name}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handlePreviewClose}
        sx={(theme) => ({
          position: 'absolute',
          right: 8,
          top: 8,
          color: theme.palette.grey[500],
        })}
      >
        <IoClose />
      </IconButton>
      <DialogContent dividers id="product-image-preview">
        <Stack>
          {thumbUrls?.map((image, index) => (
            <div key={index} className="magnify-container">
              <img src={image} alt="product-image" style={{ maxWidth: 110, height: 110, borderRadius: 8, cursor: 'pointer' }} onClick={() => setIsCurrentPreview(image)} />
            </div>
          ))}
        </Stack>
        <div className="main-image-container" style={{ width: 400, height: 400 }}>
          <div className="magnify-container" style={{ width: 400, height: 400 }}>
            <ReactImageMagnify
              {...{
                smallImage: {
                  alt: 'Product Image',
                  isFluidWidth: true,
                  src: isCurrentPreview,
                },
                largeImage: {
                  src: isCurrentPreview,
                  width: 1200,
                  height: 1800,
                },
                enlargedImageContainerDimensions: {
                  width: '150%',
                  height: '150%',
                },
              }}
            />
          </div>
        </div>
      </DialogContent>
    </BootstrapDialog>
  )
}
