import { configureStore } from '@reduxjs/toolkit'
//import { setupListeners } from "@reduxjs/toolkit/dist/query";
//import { persistReducer } from "redux-persist";
//import storage from "redux-persist/lib/storage";
//import patientReducer from './slice/patient'
//import patientReducer from 'redux/slice/patient';
import patientReducer from 'v2/src/lib/services/patient/patientSlice'
//import { userApi } from './api/User'

import nHAppReducer, {
  nHApi, //slNameReducer
} from 'v2/src/lib/services/public/nHApi'
import { authApi } from 'v2/src/lib/services/auth/authApi'
import authReducer from 'v2/src/lib/services/auth/authSlice'
import lngReducer from 'v2/src/lib/services/auth/langSlice'
import { nHDocumentApi } from 'v2/src/lib/services/nHDocumentApi'
import { locationApi } from 'v2/src/lib/services/locationApi'
import { profileApi } from 'v2/src/lib/services/profileApi'
import { providerApi } from 'v2/src/lib/services/provider/providerApi'
import { nHSubscriptionApi } from 'v2/src/lib/services/nHSubscriptionApi'
import { nHAppointmentApi } from 'v2/src/lib/services/nHAppointmentApi'
import { nHWalletApi } from 'v2/src/lib/services/nHWalletApi'
import { nHPracticeApi } from 'v2/src/lib/services/nHPracticesApi'
import { nHAddressApi } from 'v2/src/lib/services/nHAddressApi'
import { nHServiceApi } from 'v2/src/lib/services/marketplace/nHServiceApi'
import { nHMarketPlaceApi } from 'v2/src/lib/services/nHMarketPlaceApi'
//---------------------------------------------------------

const appStore = configureStore({
  reducer: {
    [nHApi.reducerPath]: nHApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [nHDocumentApi.reducerPath]: nHDocumentApi.reducer,
    [locationApi.reducerPath]: locationApi.reducer,
    [profileApi.reducerPath]: profileApi.reducer,
    [providerApi.reducerPath]: providerApi.reducer,
    [nHSubscriptionApi.reducerPath]: nHSubscriptionApi.reducer,
    [nHAppointmentApi.reducerPath]: nHAppointmentApi.reducer,
    [nHWalletApi.reducerPath]: nHWalletApi.reducer,
    [nHPracticeApi.reducerPath]: nHPracticeApi.reducer,
    [nHAddressApi.reducerPath]: nHAddressApi.reducer,
    [nHServiceApi.reducerPath]: nHServiceApi.reducer,
    [nHMarketPlaceApi.reducerPath]: nHMarketPlaceApi.reducer,
    auth: authReducer,
    lang: lngReducer,
    nHDocumentApi: nHDocumentApi,
    locationApi: locationApi,
    profileApi: profileApi,
    providerApi: providerApi,
    patient: patientReducer,
    nHSubscriptionApi: nHSubscriptionApi,
  },
  //devTools: process.env.NODE_ENV !== 'production',
  middleware: (gDM) =>
    gDM({ serializableCheck: false }).concat([
      nHApi.middleware,
      authApi.middleware,
      nHDocumentApi.middleware,
      locationApi.middleware,
      profileApi.middleware,
      providerApi.middleware,
      nHSubscriptionApi.middleware,
      nHAppointmentApi.middleware,
      nHWalletApi.middleware,
      nHPracticeApi.middleware,
      nHAddressApi.middleware,
      nHServiceApi.middleware,
      nHMarketPlaceApi.middleware,
      //userApi.middleware,
    ]),
})

//setupListeners(appStore.dispatch);
//export const persistor = persistStore(appStore);
export default appStore
