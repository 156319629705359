import { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import CustomTable from '../../wallet/CustonTable'
import { useLazyGetServiceListQuery } from 'v2/src/lib/services/marketplace/nHServiceApi'

function ServicesN({ limit }) {
  const { t: provider_services } = useTranslation('provider_services')
  const navigate = useNavigate()

  const [practiceList, setPracticeList] = useState([])
  const [page, setPage] = useState(1)
  const [rowPerPage, setRowPerPage] = useState(0)
  const [total, setTotal] = useState(0)

  const [getServiceList, { data: serviceListData, isLoading: isServiceListDataLoading, error: serviceListDataError }] = useLazyGetServiceListQuery()

  const transactionColumn = [
    { id: 'id', label: provider_services('id') },
    { id: 'name', label: provider_services('practice_name') },
    { id: 'booking_availability', label: provider_services('booking_availability') },
    { id: 'amount', label: provider_services('price_rate') },
  ]

  useEffect(() => {
    if (serviceListData && serviceListData?.data) {
      setPracticeList(serviceListData?.data)
      setRowPerPage(serviceListData?.meta?.per_page)
      setTotal(serviceListData?.meta?.total)
    }
  }, [serviceListData])

  useEffect(() => {
    const params = new URLSearchParams()
    params.append('page', page)
    params.append('per_page', 10)
    getServiceList(params).unwrap()
  }, [page])

  const handlePageChange = (number) => {
    setPage(number)
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-sm-12">
          <div className="page-title-box">
            <div className="d-flex justify-content-between align-items-center">
              <h4 className="page-title">{provider_services('services')}</h4>
              <ol className="breadcrumb">
                <Link to="manage/new">
                  <button type="button" className="btn btn-success waves-effect waves-light">
                    {provider_services('new_service')}
                  </button>
                </Link>
              </ol>
            </div>
          </div>
        </div>
      </div>
      {isServiceListDataLoading ? (
        <div className="d-flex justify-content-center w-100">
          <RingLoading size={100} />
        </div>
      ) : (
        <div className="bg-white p-2">
          <CustomTable
            columns={transactionColumn}
            rows={practiceList}
            total={total}
            perPage={rowPerPage}
            page={page}
            setPage={handlePageChange} // Pass the handler for page change
            isClickableRow
            handleRowClick={(id) => navigate(`/provider/services/manage/update/${id}`)}
          />
        </div>
      )}
    </div>
  )
}

export default ServicesN
