import { Button, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { FaMinus, FaPlus } from 'react-icons/fa'

export default function AddToCartButton({ activeProducts, product, cartQuantity, onAddToCart, onQuantityChange, isInCartButton, sx }) {
  const { t: patient_marketplace } = useTranslation('patient_marketplace')

  return isInCartButton ? (
    <Stack direction="row" gap={1.5} px={3} py="10px" borderRadius="50px" border="1px solid #008100" className="justify-content-between" alignSelf="center" width={185}>
      <Button sx={{ minWidth: 0, py: 0, px: 0.5, color: '#008100' }} className="border-0 bg-transparent" onClick={() => onQuantityChange(product?.product?.id, 'decrease')}>
        <FaMinus />
      </Button>

      <h6 className="m-0" style={{ fontSize: 16 }}>
        {cartQuantity}
      </h6>

      <Button
        sx={{ minWidth: 0, py: 0, px: 0.5, color: '#008100' }}
        className="border-0 bg-transparent"
        onClick={() => onQuantityChange(product?.product?.id, 'increase')}
        disabled={cartQuantity >= product?.variant?.quantity}
      >
        <FaPlus />
      </Button>
    </Stack>
  ) : (
    <span md="auto">
      {!activeProducts.includes(product.id) || cartQuantity < 1 ? (
        <Button
          className="text-white border-0"
          sx={{ width: 'auto', textTransform: 'capitalize', backgroundColor: '#008100', borderRadius: '50px', padding: '6px 20px' }}
          onClick={() => onAddToCart(product)}
        >
          {patient_marketplace('add_to_cart')}
        </Button>
      ) : (
        <>
          <h6 style={{ color: '#797979' }}>
            {cartQuantity} {patient_marketplace('added_to_cart')}
          </h6>
          <Stack direction="row" gap={1.5} px={3} py="10px" borderRadius="50px" border="2px solid #008100" className="align-items-center justify-content-between" sx={{ ...sx }}>
            <Button sx={{ minWidth: 0, py: 0, px: 0.5, color: '#008100' }} className="border-0 bg-transparent" onClick={() => onQuantityChange(product.id, 'decrease')}>
              <FaMinus />
            </Button>

            <h6 className="m-0" style={{ fontSize: 16 }}>
              {cartQuantity}
            </h6>

            <Button
              sx={{ minWidth: 0, py: 0, px: 0.5, color: '#008100' }}
              className="border-0 bg-transparent"
              onClick={() => onQuantityChange(product.id, 'increase')}
              disabled={cartQuantity >= product?.quantity}
            >
              <FaPlus />
            </Button>
          </Stack>
        </>
      )}
    </span>
  )
}
