import { useState } from 'react'
import { Box, createTheme, Divider, IconButton, LinearProgress, linearProgressClasses, Stack, styled, ThemeProvider, Typography } from '@mui/material'
import { Col, Form, InputGroup, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { FaSearch } from 'react-icons/fa'
import { IoStar } from 'react-icons/io5'
import moment from 'moment'

const theme = createTheme({
  typography: { fontFamily: 'Poppins, sans-serif' },
})

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  width: '100%',
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: '#00810033',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: '#008100',
  },
}))

export default function MarketplaceReviewRatingTab({ productRatings, searchReviewText, setSearchReviewText }) {
  const { t: patient_marketplace } = useTranslation('patient_marketplace')
  // Function to render filled and empty stars based on rating
  const renderStars = (rating) => {
    if (rating) {
      const fullStars = Math.floor(rating) // Number of full stars
      const hasHalfStar = rating % 1 >= 0.5 // If there is a half star
      const emptyStars = 5 - Math.ceil(rating) // Remaining stars to be empty

      return (
        <>
          {[...Array(fullStars)].map((_, i) => (
            <IoStar key={`full-${i}`} color="#FFCA22" style={{ marginRight: '4px' }} size={20} />
          ))}
          {hasHalfStar && <IoStar key="half" color="#FFCA22" style={{ marginRight: '4px' }} size={20} />}
          {[...Array(emptyStars)].map((_, i) => (
            <IoStar key={`empty-${i}`} color="#D9D9D9" style={{ marginRight: '4px' }} size={20} />
          ))}
        </>
      )
    }
  }

  return (
    <ThemeProvider theme={theme}>
      {/* Ratings */}
      <Stack gap={2}>
        <Stack gap={1}>
          <h5 className="m-0" style={{ fontSize: 18, fontWeight: 600 }}>
            {patient_marketplace('ratings')}
          </h5>
          <div className="d-flex align-items-center" style={{ gap: 24 }}>
            <Stack justifyContent="space-between" maxWidth={160} borderRadius={2} className="p-3 align-items-center w-100" style={{ height: 170, backgroundColor: '#f1f5fa' }}>
              <Typography variant="h3" fontWeight={700} fontFamily="inherit">
                {productRatings?.statistics?.average_rating}
              </Typography>
              <Stack gap={1} textAlign="center">
                <span className="mt-2">{renderStars(Number(productRatings?.statistics?.average_rating))}</span>
                <span style={{ color: '#9ea5ba', fontSize: 13.5 }}>
                  ({productRatings?.statistics?.total_ratings || patient_marketplace('no_ratings')} {patient_marketplace('ratings')})
                </span>
              </Stack>
            </Stack>

            {/* Distributions Ratings in Details */}
            <div style={{ width: '100%', maxWidth: 280, height: 170 }}>
              <Stack justifyContent="space-between" gap={2} className="h-100">
                {Array.from({ length: 5 })
                  .map((_, ind) => (
                    <div className="d-flex align-items-center" style={{ gap: 12, maxHeight: 20 }}>
                      <Box display="flex" gap={0.5} alignItems="center">
                        <span style={{ fontWeight: 500, lineHeight: 'normal' }}>{ind + 1}</span>
                        <IoStar style={{ color: '#FFCA22', width: 20, height: 20 }} />
                      </Box>
                      <BorderLinearProgress variant="determinate" value={productRatings?.statistics?.rating_distribution[ind + 1]} />
                      <span style={{ width: 20, fontSize: 15, textAlign: 'right', fontWeight: 500 }}>{productRatings?.statistics?.rating_distribution[ind + 1]}</span>
                    </div>
                  ))
                  .reverse()}
              </Stack>
            </div>
          </div>
        </Stack>

        {/* Reviews */}
        <Stack gap={2} maxWidth={650} width="100%">
          <h5 style={{ fontSize: 18, margin: 0, fontWeight: 600 }}>{patient_marketplace('reviews')}</h5>

          {/* Search */}
          <Row
            className="align-items-center"
            style={{
              border: '1px solid #F1F5FA',
              borderRadius: '50px',
              padding: '0 2px',
              margin: 0,
              gap: 8,
              maxWidth: 500,
              flexWrap: 'nowrap',
              backgroundColor: 'rgb(241, 245, 250)',
            }}
          >
            <Col md className="p-0">
              <InputGroup>
                <Form.Control
                  type="search"
                  placeholder={patient_marketplace('search_topics')}
                  value={searchReviewText}
                  onChange={(e) => {
                    setSearchReviewText(e.target.value)
                  }}
                  style={{ border: 0, borderRadius: '50px', backgroundColor: 'rgb(241, 245, 250)' }}
                />
              </InputGroup>
            </Col>

            {/* Search Button */}
            <span md="auto" style={{ marginRight: 6 }}>
              <IconButton sx={{ width: 32, height: 32, color: '#9EA5BA' }}>
                <FaSearch />
              </IconButton>
            </span>
          </Row>

          {/* List of ratings */}
          <ul style={{ paddingLeft: 4 }}>
            {productRatings?.data && productRatings?.data?.length > 0
              ? productRatings?.data?.map((review, index) => (
                  <div key={index}>
                    <Stack gap={0.5}>
                      <Typography fontSize={14} fontWeight={500} sx={{ color: '#42323B' }}>
                        {review?.user?.first_name + ' ' + review?.user?.last_name}
                      </Typography>
                      <div className="d-flex align-items-center" style={{ flexWrap: 'wrap', gap: 8, height: 36 }}>
                        <h3 className="m-0 me-3" style={{ fontWeight: 600, fontSize: 24, color: '#222525' }}>
                          {(review?.rating).toFixed(1)}
                        </h3>
                        <div className="d-flex align-items-center">
                          {[...Array(5)].map((_, i) => {
                            const rating = review.rating || 0
                            const fullStar = i < Math.floor(rating)
                            const halfStar = i === Math.floor(rating) && rating % 1 >= 0.5
                            return <IoStar key={i} color={fullStar ? '#FFCA22' : halfStar ? '#FFCA22' : '#D9D9D9'} style={{ marginRight: '4px' }} size={20} />
                          })}
                        </div>
                        <span style={{ fontSize: 13.5, marginTop: 5, color: '#767676' }}>{moment(review?.created_at).format('ddd, MMMM YY')}</span>
                      </div>
                      <p className="m-0" style={{ fontSize: 14 }}>
                        {review?.review}
                      </p>
                    </Stack>
                    <Divider className="my-3" sx={{ borderColor: '#F8F5F2' }} />
                  </div>
                ))
              : patient_marketplace('no_ratings')}
          </ul>
        </Stack>
      </Stack>
    </ThemeProvider>
  )
}
