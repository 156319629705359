// discounted tag
export function DiscountedButton({ basePrice, discountedPrice }) {
  return (
    <span className="mr-2" style={{ fontSize: 12, backgroundColor: '#F04D4A', color: '#fff', borderRadius: 50, padding: '2px 10px' }}>
      {((parseFloat(basePrice) - parseFloat(discountedPrice)) / parseFloat(basePrice)) * 100}% OFF
    </span>
  )
}

// Base Price if discounted Price amount Available
export function BasePriceIfHasDiscount({ basePrice, fontSize }) {
  return (
    <span className="mr-2" style={{ color: '#989898', fontSize: fontSize ? fontSize : 20, textDecoration: 'line-through' }}>
      ${basePrice}
    </span>
  )
}
