import React, { useState } from 'react'
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js'
import { useTranslation } from 'react-i18next'

const CheckoutForm2 = ({ param, handlePostAppointment, handleClose }) => {
  const { t: patient_marketplace } = useTranslation('patient_marketplace')
  const stripe = useStripe()
  const elements = useElements()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const handleSubmit = async () => {
    setLoading(true)
    setError(null)

    if (!stripe || !elements) {
      setLoading(false)
      return
    }

    const cardElement = elements.getElement(CardElement)

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    })

    if (error) {
      setError(error.message)
      setLoading(false)
      return
    }

    if (paymentMethod) {
      handlePostAppointment({ ...param, payment_method_id: paymentMethod?.id })
    }
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
      <CardElement className="stripe-card-element" />
      {error && (
        <p style={{ color: 'red' }} className="m-0">
          {error}
        </p>
      )}
      <div className="w-100" style={{ marginTop: 20 }}>
        <button
          onClick={handleClose}
          disabled={!stripe || loading}
          style={{
            backgroundColor: '#fff',
            color: '#007e26',
            padding: '6px 20px',
            border: '1px solid',
            cursor: loading ? 'not-allowed' : 'pointer',
            borderRadius: '50px',
            margin: 5,
            fontSize: 16,
          }}
        >
          {patient_marketplace('cancel_appointment')}
        </button>
        <button
          onClick={handleSubmit}
          disabled={!stripe || loading}
          style={{
            backgroundColor: loading ? '#ccc' : '#008000',
            color: 'white',
            padding: '6px 20px',
            border: 'none',
            cursor: loading ? 'not-allowed' : 'pointer',
            borderRadius: '50px',
            margin: 5,
            fontSize: 16,
            fontWeight: 500,
          }}
        >
          {loading ? 'Processing...' : `${patient_marketplace('book_now')}`}
        </button>
      </div>
    </div>
  )
}

export default CheckoutForm2
