import * as yup from 'yup'

const LoginSchema = (error_message) => {
  return yup
    .object({
      Email: yup
        .string()
        .email(error_message('email_error')) // Get the translated email error message
        .required(error_message('required_error')) // Get the translated required field error
        .min(6, error_message('name_min_length_error')) // Get the translated min length error
        .max(255, error_message('name_max_length_error')), // Get the translated max length error

      Password: yup
        .string()
        .required(error_message('required_error')) // Get translated error message for required
        .min(8, error_message('password_min_length_error')) // Get translated error message for min length
        .max(50, error_message('password_max_length_error')) // Get translated error message for max length
        .matches(
          /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*()_+-={}|;:'\",.<>?]).{8,}$/,
          error_message('password_format_error') // Get translated error message for password format
        ),
    })
    .required()
}

export default LoginSchema
