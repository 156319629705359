import CarouselImage1 from 'assets/images/marketplace/banner-1.png'
import CarouselImage2 from 'assets/images/marketplace/banner-2.png'
import CarouselImage3 from 'assets/images/marketplace/banner-3.png'
import CarouselImage4 from 'assets/images/marketplace/banner-4.png'
import { ReactComponent as StethoscopeSVG } from 'assets/images/marketplace/stethoscope.svg'
import { ReactComponent as VirtualAppointmentIconSVG } from 'assets/images/marketplace/virtual-appointment-icon.svg'

export const constant = {
  carouselItems: [CarouselImage1, CarouselImage2, CarouselImage3, CarouselImage4],

  healthServices: [
    { label: 'in_clinic_doctor_appointment', icon: <StethoscopeSVG />, redirectTo: '/patient/marketplace/services' },
    { label: 'virtual_doctor_appointment', icon: <VirtualAppointmentIconSVG />, redirectTo: '/patient/virtualvisit' },
  ],

  ratingOptions: [
    { value: 5, label: '5' },
    { value: 4, label: '4' },
    { value: 3, label: '3' },
    { value: 2, label: '2' },
    { value: 1, label: '1' },
    { value: 0, label: 'Unrated' },
  ],

  SIDE_PANEL: 'side_panel',
  TAB: 'tab',

  MIN_RANGE: 0,
  MAX_RANGE: 5000,
}
