import { nHApi } from 'v2/src/lib/services/public/nHApi'

export const nHCartApi = nHApi.injectEndpoints({
  endpoints: (builder) => ({
    addCartItems: builder.mutation({
      query: (payload) => ({ url: '/ecommerce/cart/add', method: 'POST', body: payload }),
      invalidatesTags: ['carts'],
    }),

    getCartItems: builder.query({
      query: () => {
        return { url: `/ecommerce/cart`, method: 'GET' }
      },
      providesTags: ['carts'],
    }),

    // Update quantity of items in the cart
    updateCartItemQuantity: builder.mutation({
      query: (payload) => {
        return { url: `/ecommerce/cart/update/${payload?.id}`, method: 'POST', body: payload }
      },
      invalidatesTags: ['carts'],
    }),

    // Remove an item from the cart
    removeCartItem: builder.mutation({
      query: (id) => ({ url: `/ecommerce/cart/remove/${id}`, method: 'DELETE' }),
      invalidatesTags: ['carts'],
    }),

    // Coupon code APi

    applyCouponCode: builder.mutation({
      query: (payload) => ({ url: '/ecommerce/coupon/apply', method: 'POST', body: payload }),
      invalidatesTags: ['carts'],
    }),

    checkoutOrder: builder.mutation({
      query: (payload) => ({ url: '/ecommerce/checkout', method: 'POST', body: payload }),
      invalidatesTags: ['carts'],
    }),
  }),
})

export const { useAddCartItemsMutation, useGetCartItemsQuery, useUpdateCartItemQuantityMutation, useRemoveCartItemMutation, useApplyCouponCodeMutation, useCheckoutOrderMutation } = nHCartApi
