import { nHApi } from 'v2/src/lib/services/public/nHApi'

export const nHProductsApi = nHApi.injectEndpoints({
  endpoints: (builder) => ({
    // Categories
    getCategories: builder.query({
      query: () => {
        const params = new URLSearchParams()
        params.append('per_page', 100)
        params.append('page', 1)
        params.append('status', 'active')
        return { url: `/ecommerce/collections?${params.toString()}` }
      },
    }),

    // Individual Category Childrens
    getCategoryChildren: builder.query({
      query: (param) => {
        return { url: `/ecommerce/collection/${param?.categoryId}/children` }
      },
    }),

    // List of Prodcuts
    getProducts: builder.query({
      query: (params) => {
        return { url: `/ecommerce/products?${params.toString()}` }
      },
    }),

    // Get A Product based on Id
    getProduct: builder.query({
      query: (filters) => {
        return { url: `/ecommerce/product/${filters?.id}?${filters?.params.toString()}` }
      },
    }),

    // Brands
    getBrands: builder.query({
      query: (params) => {
        return { url: `/ecommerce/brands?${params.toString()}` }
      },
    }),

    // Product Form
    getProdctForm: builder.query({
      query: () => {
        return { url: 'ecommerce/dosage-forms?page=1&per_page=100' }
      },
    }),

    // Product Rating
    getProductRatings: builder.query({
      query: (params) => {
        return { url: `ecommerce/product-ratings?${params.toString()}` }
      },
    }),
  }),
})

export const { useGetCategoriesQuery, useLazyGetCategoryChildrenQuery, useLazyGetProductsQuery, useGetProductQuery, useLazyGetBrandsQuery, useLazyGetProdctFormQuery, useLazyGetProductRatingsQuery } =
  nHProductsApi
